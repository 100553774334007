import React, { Fragment, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { suggestionMasterScreen } from "./constant";
import { useSelector } from "react-redux";
import {
  noticeboardUploadDocument,
  deleteSchemeImage,
  updateSuggestionSchemeName,
  getSuggestonOfficeAgencyDashboard,
  getSuggAgency,
  editAgencyAdminMasterScreen,
  editDivsionalCordinator,
  editEvaluator,
  editDivisionAdminMasterScreen
} from "../../../../../constants/api.service";
import { configStore } from "../../../../../redux/ConfigStore";
import { toaster } from "../../../../../utils/Utils";
import delete_action from "../../../../../assets/drishti_images/transfer/delete_action.svg";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import { minImageHeight,minImageWidth,maxImageHeight,maxImageWidth} from './constant';
import LargeDropdown from "../../../../shared/components/LargeDropdown";

function UpdatePopUpScreen({
  updateFormData,
  filteredValue,
  dismisUpdateForm,
  closeUpdatePopUp,
}) {
  const [formData, setFormData] = useState(updateFormData);
  const [schemeName, setSchemeName] = useState(updateFormData?.scheme_name ?? null);
  const [startDate, setStartDate] = useState(updateFormData?.start_date ?? null);
  const [endDate, setEndDate] = useState(updateFormData?.end_date ?? null);
  const [documentData, setDocumentData] = useState(null);
  const fileInputRef = useRef(null);
  const [agencyData, setAgencyData] = useState([]);
  const [agency, setAgency] = useState(null);
  const [loading, setloading] = useState(false);
  const [attachmentName, setAttachmentName] = useState("");
  const [documentLink, setdocumentLink] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [showImage, setShowImage] = useState(
    updateFormData.image_path ? true : false
  );
  const [validateBtn,setValidateBtn] = useState(false);
  const [divisionName, setDivisionName] = useState(updateFormData?.division_name ?? null);
  const [divisionCode, setDivisionCode] = useState(updateFormData?.division_code ?? null);
  const [employeeId, setEmployeeId] = useState(null);
  const [agencyName, setAgencyName] = useState(null);
  const [agencyCode, setAgencyCode] = useState(null);
  const [divisionData, setDivisionData] = useState([]);
  const [division, setDivision] = useState("");
  const [showAgency,setShowAgency] = useState(false);
  const [costCenterNum,setCostCenterNum] = useState(null);
  const [costCenterName,setCostCenterName] = useState(null);
  const [perArea,setPerArea] =  useState(null);
  const [psa,setPsa] = useState(null);
  const [compCode,setCompCode] = useState(null);
  const adminLoginState = useSelector((state) => state.adminLoginState);

  
  const validateForm = () => {
    if(filteredValue.key == suggestionMasterScreen.SchemeList){
      if(!schemeName || !startDate || !endDate){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      updateSchemeName();
    }
    if(filteredValue.key == suggestionMasterScreen.DivisionList){
      if(!divisionName ){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      updateDivision();
    }
    if(filteredValue.key == suggestionMasterScreen.Evaluator){
      if(!division || !agency ){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      updateEvaluator();
    }

    if(filteredValue.key == suggestionMasterScreen.DivisionCordinator){
      if(!division ){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      updateDivisionalCordinator();
    }

    if(filteredValue.key == suggestionMasterScreen.AgencyList){
      if(!agencyName){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      updateAgency();
    }

  };

  const assignValue = () => {
    if(filteredValue.key == suggestionMasterScreen.AgencyList || filteredValue.key == suggestionMasterScreen.Evaluator){
      setAgencyName(updateFormData.agency_name);
      setAgencyCode(updateFormData.agency_code);
      const division = {
        id: updateFormData?.division,
        label: updateFormData?.division_name,
      }
      setDivision(division);
    }

    if(filteredValue.key == suggestionMasterScreen.DivisionCordinator){
      const division = {
        id: updateFormData?.division,
        label: updateFormData?.division_name,
      }
      setDivision(division);
      setEmployeeId(updateFormData?.emp_id);
    }

    if(filteredValue.key == suggestionMasterScreen.Evaluator){
      const agency = {
        id: updateFormData?.agency_code,
        label: updateFormData?.agency_name, 
      }
      setAgency(agency);
    }

    if(filteredValue.key == suggestionMasterScreen.CostCenter){
      const division = {
        id: updateFormData?.division_code,
        label: updateFormData?.division_name,
      }
      setDivision(division);
      setCostCenterName(updateFormData?.costcenter_name);
      setCostCenterNum(updateFormData?.costcenter_no);
      setPerArea(updateFormData?.pa)
      setPsa(updateFormData?.psa);
      setCompCode({
        id:1,
        title: updateFormData?.comp_code
      })
    }

  };

  const handleSchemeName = (e) => {
    setSchemeName(e);
  };

  const handleStartDate = (e) => {
    setStartDate(e);
    setEndDate('');
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleUpload = () => {
    UploadDocumentDetail(
      documentLink?.documentLink,
      attachmentName?.documentType,
      attachmentName?.documentName
    );
    setAttachmentName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setShowImage(false);
  };

  const UploadDocumentDetail = async (
    attachment,
    attachmentType,
    attachmentName
  ) => {
    setloading(true);
    let body = {
      emp_id: configStore.getState().adminLoginState?.userData?.PersArea,
      attachment: attachment,
      attachment_name: attachmentName,
      attachment_type: attachmentType,
    };
    await noticeboardUploadDocument(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          const document = {
            // type: viewDocumentTypeFilter?.title,
            name: res?.data?.data?.attachment_name,
            extension: attachmentType,
            attachmentURL: res?.data?.data?.attachment_presigned_url,
            attachment: res?.data?.data?.attachment_path,
            path: res?.data?.data?.attachment_path,
            document_category: "Scheme name",
          };
          // setdocumentData([...documentData, document]);
          setDocumentData(document);
          setShowDocument(true);
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
        }
      })
      .catch((error) => {
        console.log("error in S3 delete documents-->", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
      })
      .finally(() => {
        setloading(false);
      });
  };

  const fileSelectHandler = (event) => {
    handleImageUpload(event);
    if (event?.target?.files && event?.target?.files[0]) {
      let typeOfImage = event?.target?.files[0]?.type;
      let ImageType = typeOfImage.split("/");
      setAttachmentName({
        documentName: event?.target?.files[0]?.name,
        documentType: ImageType[1] || "",
      });

      let maxAllowedSize = 2097152; // 2MB size
      if (event?.target?.files[0]?.size <= maxAllowedSize) {
        let reader = new FileReader();
        reader.readAsDataURL(event?.target?.files[0]);
        reader.onload = (e) => {
          setdocumentLink({ documentLink: e.target.result });
        };
      } else {
        toast.warn("Please upload image less than 2MB", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
        setdocumentLink({ documentLink: "" });
      }
    } else {
      setAttachmentName({ documentName: "", documentType: "" });
      setdocumentLink({ documentLink: "" });
    }
  };

  const validateImage = (file) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = function () {
      // Validate image dimensions
      if (
        img.width >= minImageWidth && img.width <= maxImageWidth &&
        img.height >= minImageHeight && img.height <= maxImageHeight
      ){
        setValidateBtn(true);
      } else {
        toaster("warning",`Please upload an image with dimensions between ${minImageWidth}x${minImageHeight} and ${maxImageWidth}x${maxImageHeight} pixels.`);
        setValidateBtn(false);
      }
      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      validateImage(file);
    }
  };

  const handleDeleteDocument = async () => {
    setloading(true);
    let body = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await deleteSchemeImage(body, formData.id)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
        }
      })
      .catch((error) => {
        console.log("error-->", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
      })
      .finally(() => {
        setloading(false);
      });
    setShowImage(false);
  };

  const handleDivisionName = (e) =>{
    setDivisionName(e);
  }

  const handleDivisionCode = (e) =>{
    setDivisionCode(e);
  }

  const handleAgencyName = (e) =>{
    setAgencyName(e);
  }

  const handleAgencyCode = (e) =>{
    setAgencyCode(e);
  }

  const handleDivision = (val)=>{
    if(filteredValue.key !== suggestionMasterScreen.DivisionCordinator){
      setAgency(null);
      setAgencyData([]);
      getAgency(val.id)
    }
    setDivision(val);
  }

  const handleAgency = (val)=>{
    setAgency(val);
  }

  const getClassNames = ()=>{
    const baseClass = 'modal-content bg-light ';
    const result = {};
    if(filteredValue.key == suggestionMasterScreen.SchemeList){
      result.modalClass = baseClass+'toDoModalMedium'
      result.modalHeight = 'h-80';
      result.formHeight = 'todoModalBody w-100 h-100';
      return result;
    }
    if(filteredValue.key == suggestionMasterScreen.DivisionList){
      result.modalClass = baseClass+'toDoModal';
      result.modalHeight = 'h-70';
      result.formHeight = 'h-80';
      return result;
    }
    if(filteredValue.key == suggestionMasterScreen.AgencyList){
       result.modalClass = baseClass+'toDoModalMedium'
       result.modalHeight = 'h-80';
       result.formHeight = 'todoModalBody w-100 h-100';
       return result;
    }
    result.modalClass = baseClass+'toDoModalMedium'
    result.modalHeight = 'h-80';
    result.formHeight = 'todoModalBody w-100 h-100';
    return result;
  }

  const handleCostCenterNum = (val)=>{
    setCostCenterNum(val);
  }

  const handleCostCenterName = (val)=>{
    setCostCenterName(val);
  }

  const handlePerArea = (val)=>{
    setPerArea(val);
  }

  const handlePsa = (val)=>{
    setPsa(val);
  }

  const handleCompCode = (val)=>{
    setCompCode(val);
  }

  // ---------------------Get Division Details------------ //
  const getDivision = () => {
    setloading(true);
    const param = {
      comp_code: adminLoginState?.userData?.CompCode,
      pa: adminLoginState?.userData?.PersArea,
    };
    getSuggestonOfficeAgencyDashboard(param)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const division = res?.data?.data?.map((division) => ({
            id: division?.division_code,
            label: division?.division_name,
          }));
          setDivisionData(division);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
          setDivisionData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setloading(false);
      });
  };

  
  // ---------------------Get Agency Details Based of Division ID------------ //
  const getAgency = (divisionId) => {
    setloading(true);
    const params = { division: divisionId };
    getSuggAgency(params)
        .then(res => {
            if (res?.status === 200) {
                const agency_list = res?.data?.data?.agency_list.map(agency => ({
                    id: agency?.agency_code,
                    label: agency?.agency_name
                }));
                setAgencyData(agency_list);
            } else {setAgencyData([]);}
        })
        .catch(errors => {
            toast.warn(errors, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        })
        .finally(() => {setloading(false);});
  };


  //--------------------- Update Scheme -----------------------------//
  const updateSchemeName = async () => {
    setloading(true)
    let pa = configStore.getState().adminLoginState?.userData?.PersArea;
    let comp_code =
      configStore.getState().adminLoginState?.company?.company_code;
    let body = {
      scheme_name: schemeName,
      start_date: startDate,
      end_date: endDate,
      image_path: documentData?.path,
    };
    const endpoint = formData.id + "?comp_code=" + comp_code + "&pa=" + pa;
    await updateSuggestionSchemeName(body, endpoint)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          closeUpdatePopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
            closeUpdatePopUp();
        }
      })
      .catch((error) => {
        console.log("error-->", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
        closeUpdatePopUp();
      })
    .finally(() => { setloading(false) })
  };

  //------------------ Update Agency --------------------------//
  const updateAgency = async () => {
    setloading(true);
    const param = {
      agency_name: agencyName?.trim() ?? '',
      division: division?.id ? updateFormData?.division : '' 
    };
    await editAgencyAdminMasterScreen(updateFormData?.agency_code,param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "Agency details updated sucessfully");
          closeUpdatePopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
            closeUpdatePopUp();
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setloading(false);
        dismisUpdateForm();
      });
  };

  //------------------ Update Divisional cordinator---------------------//
  const updateDivisionalCordinator = async () => {
    setloading(true);
    const param = {
      emp_id: employeeId,
      division_code: division?.id,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await editDivsionalCordinator(param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "Divisional cordinator details updated sucessfully");
          closeUpdatePopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
            closeUpdatePopUp();
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setloading(false);
        dismisUpdateForm();
      });
  };

  //------------------ Update Evaluator---------------------//
  const updateEvaluator = async () => {
    setloading(true);
    const param = {
      emp_id: employeeId,
      division_code: division?.id,
      agency_code: agency?.id,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await editEvaluator(param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "Divisional cordinator details updated sucessfully");
          closeUpdatePopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
            closeUpdatePopUp();
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setloading(false);
        dismisUpdateForm();
      });
  };

  //------------------ Update Division --------------------------//
  const updateDivision = async () => {
    setloading(true);
    const param = {
      division_name: divisionName?.trim() ?? '',
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await editDivisionAdminMasterScreen(updateFormData?.division_code,param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "Division details updated sucessfully");
          closeUpdatePopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
            closeUpdatePopUp();
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setloading(false);
        dismisUpdateForm();
      });
  };

  useEffect(()=>{
    getDivision();
    assignValue();
  },[]);

  const applyClassNames = getClassNames();

  return (
    <div className="modal popup-box" id="addTodoModal">
      <LoaderIndicator loading={loading} />
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className={applyClassNames?.modalClass}>
          <div className="modal-header edit-cardtitle">
            <h4 className="modal_title">Edit {filteredValue.value}</h4>
            <button type="button" className="close" onClick={dismisUpdateForm}>
              &times;
            </button>
          </div>
          <div className={applyClassNames?.modalHeight}>
          <form className={applyClassNames?.formHeight}>
              <div className="p-1 w-100">
                <div>
                  <div>
                    {/* Scheme List */}
                    {filteredValue.key == suggestionMasterScreen.SchemeList && (
                      <>
                        <div className="p-sub-heading ml-3 required">
                          {suggestionMasterScreen.SchemeName}
                        </div>
                        <div className="p-placeholder-text mb-2 w-50rem">
                          <input
                            className="form-control p-placeholder p-placeholder-admin px-2"
                            type="text"
                            onChange={(e) => handleSchemeName(e.target.value)}
                            value={schemeName || ""}
                            placeholder={"Enter Scheme Name"}
                            // maxLength={50}
                          />
                        </div>
                        <div
                          className="scroll-webkit-x"
                          style={{ width: "90%" }}
                        >
                          <span className="mr-20px w-45-percent">
                            <div className="p-sub-heading ml-3 required">
                              {suggestionMasterScreen.StartDate}
                            </div>
                            <div className="p-placeholder-text mb-2">
                              <input
                                className="form-control p-placeholder"
                                type="date"
                                value={startDate || ""}
                                style={{ height: "38px" }}
                                onChange={(e) =>
                                  handleStartDate(e.target.value)
                                }
                                min={today}
                              />
                            </div>
                          </span>

                          <span className="w-45-percent">
                            <div className="p-sub-heading ml-3 required">
                              {suggestionMasterScreen.EndDate}
                            </div>
                            <div className="p-placeholder-text mb-2">
                              <input
                                className="form-control p-placeholder"
                                type="date"
                                value={endDate || ""}
                                style={{ height: "38px" }}
                                onChange={(e) => handleEndDate(e.target.value)}
                                min={startDate}
                                disabled={!startDate}
                              />
                            </div>
                          </span>
                        </div>
                        {showImage && (
                          <div>
                            <div className="p-sub-heading ml-3">
                              Image(Optional)
                            </div>
                            <div
                              className="scroll-webkit-x"
                              style={{ width: "85%" }}
                            >
                             <div className="sugg-container">
                                <img src={formData.image_path} alt="Uploaded Image" className="sugg-container-Image" />
                              </div>
                              <div>
                                <img
                                  src={delete_action}
                                  alt="Delete"
                                  style={{ width: "30px" }}
                                  className="pointer mx-1"
                                  onClick={() => handleDeleteDocument(formData)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div>
                          <div>
                            <div className="p-sub-heading">Upload Document</div>
                            <div
                              className="scroll-webkit-x"
                              style={{ width: "80%", marginBottom: "5px" }}
                            >
                              <div className="p-placeholder-text">
                                <input
                                  className="form-control p-placeholder"
                                  type="file"
                                  accept=".png,.jpg,.jpeg,.pdf"
                                  ref={fileInputRef}
                                  style={{ height: "43px" }}
                                  placeholder={"Select Document"}
                                  onChange={(e) => fileSelectHandler(e)}
                                />
                                {/* {errors.attachmentName && <span className="p-sub-heading ml-3 errorBlock">{errors.attachmentName}</span>} */}
                                <div>
                                  <span className="noteTextMsg">
                                    {
                                      "You can upload Attachment upto 2MB size only.(pdf/png/jpg/jpeg)"
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="col-12 col-md-3 mb-4 d-flex  align-items-center">
                                <input
                                  type="button"
                                  value="UPLOAD"
                                  className={`${validateBtn ? 'validateBtn' : 'validateBtnDisabled'}`}
                                  onClick={handleUpload}
                                  disabled={!validateBtn}
                                />
                              </div>
                            </div>
                            <div>
                              <span className="noteTextMsg">
                                {
                                  `Note: Upload an image with a minimum width of ${minImageWidth}px and height of ${minImageHeight}px, or a maximum width of ${maxImageWidth}px and height of ${maxImageHeight}px`
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                        {showDocument && (
                          <div className="sugg-container1">
                            <img className="sugg-container-Image" src={documentData?.attachmentURL} alt="Uploaded Image" />
                          </div>
                        )}
                      </>
                    )}

                    {/* Division */}
                    {filteredValue.key == suggestionMasterScreen.DivisionList && (
                      <div className="p-1 w-100">
                      <div>
                        <div className="p-sub-heading ml-4 required">
                          {suggestionMasterScreen.DivisionName}
                        </div>
                        <div className="p-placeholder-text mb-2 ml-3 w-25rem">
                          <input
                            className="form-control p-placeholder p-placeholder-admin px-2"
                            type="text"
                            onChange={(e) => handleDivisionName(e.target.value)}
                            value={divisionName}
                            placeholder={"Enter Division Name"}
                            // maxLength={50}
                          />
                        </div>
                        <div className="p-sub-heading ml-4 required">
                          {suggestionMasterScreen.DivisionCode}
                        </div>
                        <div className="p-placeholder-text mb-2 ml-3 w-25rem">
                          <input
                            className="form-control p-placeholder p-placeholder-admin px-2"
                            type="text"
                            onChange={(e) => handleDivisionCode(e.target.value)}
                            value={divisionCode}
                            placeholder={"Enter Division Code"}
                            // maxLength={50}
                            disabled = {true}
                          />
                        </div>
                      </div>
                      </div>
                    )}

                    {/* Agency */}
                    {filteredValue.key == suggestionMasterScreen.AgencyList && (
                      <div className="p-1 w-100">
                        <div>
                          <div className="p-sub-heading ml-3 required">
                            {suggestionMasterScreen.AgencyName}
                          </div>
                          <div className="p-placeholder-text mb-2 w-35rem">
                            <input
                              className="form-control p-placeholder p-placeholder-admin px-2"
                              type="text"
                              onChange={(e) => handleAgencyName(e.target.value)}
                              value={agencyName}
                              placeholder={"Enter Agency Name"}
                              // maxLength={50}
                            />
                          </div>
                          {/* <div className="p-sub-heading ml-3 required">
                            {suggestionMasterScreen.AgencyCode}
                          </div>
                          <div className="p-placeholder-text mb-2 w-35rem">
                            <input
                              className="form-control p-placeholder p-placeholder-admin px-2"
                              type="text"
                              onChange={(e) => handleAgencyCode(e.target.value)}
                              value={agencyCode}
                              placeholder={"Enter Agency Code"}
                              // maxLength={50}
                            />
                          </div> */}

                          <div className="p-sub-heading ml-3 required">
                            Select Division
                          </div>
                          <div className="p-placeholder-text mb-2 w-25rem">
                            <LargeDropdown
                              data={divisionData}
                              value={division?.label ?? 'Select'}
                              selectedValue={(data) => handleDivision(data)}
                              disabled={true}
                            />
                          </div>

                          {/* {
                            division ? 
                            <div>
                              <div className="p-sub-heading ml-3 required">
                                {suggestionMasterScreen.DivisionCode}
                                </div>
                                <div className="p-placeholder-text mb-2 w-20rem">
                                  <input
                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                    type="text"
                                    // onChange={(e) => handleAgencyCode(e.target.value)}
                                    value={division?.id ?? ''}
                                    // placeholder={"Enter Agency Code"}
                                    disabled={true}
                                    // maxLength={50}
                                  />
                              </div>
                            </div> : null
                          } */}

                        </div>
                      </div>
                    )}

                    {/* Evaluator */}
                    {(filteredValue.key == suggestionMasterScreen.Evaluator || filteredValue.key == suggestionMasterScreen.DivisionCordinator) && (
                      <div className="p-1 w-100">
                        <div>
                          <div className="p-sub-heading ml-3 required">
                            {suggestionMasterScreen.EmployeeId}
                          </div>
                          <div className="scroll-webkit-x">
                            <div className="p-placeholder-text mb-2 w-25rem scroll-webkit-x">
                              <span>
                                <input
                                  className="form-control p-placeholder"
                                  type="text"
                                  value={employeeId ?? '-'}
                                  placeholder='Enter Employee ID'
                                  // onChange={(e) => handleEmployeeChange(e.target.value)}
                                  disabled={true}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="p-sub-heading ml-3 required">
                            Select Division
                          </div>
                          <div className="p-placeholder-text mb-2 w-25rem">
                            <LargeDropdown
                              data={divisionData}
                              value={division?.label ?? 'Select'}
                              selectedValue={(data) => handleDivision(data)}
                              // isDisabled = {!validateDropdown}
                            />
                          </div>
                          {
                            showAgency ? 
                            <div>
                              <div className="p-sub-heading ml-3 required">
                                Select Agency
                              </div>
                              <div className="p-placeholder-text mb-2 w-25rem">
                                <LargeDropdown
                                  data={agencyData}
                                  value={agency?.label ?? 'Select'}
                                  selectedValue={(data) => handleAgency(data)}
                                />
                              </div>
                            </div> : null
                          }
                        </div>
                      </div>
                    )}

                    {/* Cost Center */}
                    {/* {(filteredValue.key == suggestionMasterScreen.CostCenter) && (
                      <div className="p-1 w-100">
                        <div className="w-25rem">
                            <div className="p-sub-heading ml-3 required">
                              {suggestionMasterScreen.CostCenterName}
                            </div>
                            <div className="p-placeholder-text mb-2">
                              <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => handleCostCenterName(e.target.value)}
                                value={costCenterName}
                                placeholder={"Enter Cost Center Name"}
                                // maxLength={50}
                              />
                            </div>
                        </div>

                        <div className="justify-content-start">
                          <div className="w-20rem mr-4_5rem">
                            <div className="p-sub-heading ml-3 required">
                              {suggestionMasterScreen.CostCenterNo}
                            </div>
                            <div className="p-placeholder-text mb-2">
                              <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => handleCostCenterNum(e.target.value)}
                                value={costCenterNum}
                                placeholder={"Enter Cost Center Number"}
                                // maxLength={50}
                              />
                            </div>
                          </div>
                          <div className="w-20rem">
                            <div className="p-sub-heading ml-3 required">
                              {suggestionMasterScreen.pa}
                            </div>
                            <div className="p-placeholder-text mb-2">
                              <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => handlePerArea(e.target.value)}
                                value={perArea}
                                placeholder={"Enter Person Area"}
                                // maxLength={50}
                              />
                            </div>
                          </div>
                        </div>
                        
                        <div className="justify-content-start">
                          <div className="w-20rem mr-4_5rem">
                            <div className="p-sub-heading ml-3 required">
                              {suggestionMasterScreen.psa}
                            </div>
                            <div className="p-placeholder-text mb-2">
                              <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => handlePsa(e.target.value)}
                                value={psa}
                                placeholder={"Enter Person Sub Area"}
                                // maxLength={50}
                              />
                            </div>
                          </div>

                          <div className="w-20rem">
                            <div className="p-sub-heading ml-3 required">
                              {suggestionMasterScreen.compcode}
                            </div>
                            <DynamicDropdown
                              data={companyCode}
                              render="title"
                              value={compCode?.title ?? 'Select'}
                              selectedValue={(data) => handleCompCode(data)}
                              arrow={"true"}
                              height={"NotificationHeight"}
                            />
                          </div>
                        </div>

                        <div className="p-sub-heading ml-3 required">
                            Select Division
                        </div>
                        <div className="p-placeholder-text mb-2 w-20rem">
                            <LargeDropdown
                              data={divisionData}
                              value={division?.label ?? 'Select'}
                              selectedValue={(data) => handleDivision(data)}
                              // disabled = {!validateDropdown}
                            />
                        </div>
                      </div>
                    )} */}

                  </div>
                </div>
              </div>
            </form>
            <div className="modal-footer btnBox justify-content-end">
              <div className="justify-content-around mb-4px">
                <div className="justify-content-start">
                  <input
                    type="button"
                    value="CANCEL"
                    className="popup-back-btn popup-back-text"
                    onClick={(e) => dismisUpdateForm()}
                  />
                </div>
                <div className="justify-content-start">
                  <input
                    type="button"
                    value="UPDATE"
                    className="popup-confirm-btn"
                    onClick={(e) => validateForm()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default UpdatePopUpScreen;
