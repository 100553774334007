import React, { Component } from 'react';
import BigCalendar from './BigCalendar';
import '../../../../assets/styles/basic.css';
import { connect } from 'react-redux';
import { leaveColorData } from '../../../../utils/Utils'
import * as homeActions from '../landingScreen/ActivePopupAction';
import ServiceUnavailable from '../../../shared/ServiceUnavailable'
import { checkActivePoup, checkPopupCount } from '../../../../utils/CheckActivePopup';
import DynamicCustomPopupModal from '../../../shared/dynamicCustomPopum/DynamicCustomPopup'
import { bindActionCreators } from 'redux';
import CommonButton from '../../../shared/button/GradientButton';
import DateFilterPopup from './DateFilterPopup';
import LwopReport from './LwopReport';
class AttendanceSheet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leaveDataColor: leaveColorData(),
            is_service_Unavailable: false,  // service unavailble from backend
            is_LWOP_service_Unavailable: false,  // service unavailble from backend
            attendance_Enquiry_Service_reason: '',

            isActive: false,
            message: '',
            note_content: '',
            note_title: '',
            note_type: '',
            image_url: '',
            image_hyperlink: '',
            isAttendanceReportPopup: false,
            isAttendanceLwopPopup:false
        }
    }

    componentDidMount() {
        this.checkService();
        this.checkActivePoupDetails();
    }

    hideModal = () => { this.setState({ isActive: !this.state.isActive }) }

    checkActivePoupDetails() {
        var activeDetails = checkActivePoup('attendance');
        this.setState({
            isActive: activeDetails && checkPopupCount('attendance') ? true : false,
            message: activeDetails ? activeDetails : '',
            note_content: activeDetails.note_content ? activeDetails.note_content : '',
            note_title: activeDetails.note_title ? activeDetails.note_title : '',
            note_type: activeDetails.note_type ? activeDetails.note_type : '',
            image_url: activeDetails.image_url ? activeDetails.image_url : '',
            image_hyperlink: activeDetails.image_hyperlink ? activeDetails.image_hyperlink : '',
        }, () => {
            if (this.state.isActive) {
                this.props.homeActions.getActivePopupCount('attendance')
            }
        })
    }

    hideModal = () => { this.setState({ isActive: !this.state.isActive }) }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "time_attendance" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    attendance_Enquiry_Service_reason: item.reason
                })
            }
            if (item.service_type == "leave_lwop_Report" && item.is_active == false) {
                this.setState({
                    is_LWOP_service_Unavailable: true,
                })
            }
        })
    }

    handleButtonClick = () => {
        this.setState({
            isAttendanceReportPopup: !this.state.isAttendanceReportPopup
        })
    }

    handleLwopButtonClick = () => {
        this.setState({
            isAttendanceLwopPopup: !this.state.isAttendanceLwopPopup
        })
    }

    render() {
        const { leaveDataColor, isAttendanceReportPopup,isAttendanceLwopPopup } = this.state
        return (
            <div>
                {this.state.is_service_Unavailable ?
                    <div className="col-md-12 h-100vh justify-content-center align-item-center">
                        <ServiceUnavailable reason={this.state.attendance_Enquiry_Service_reason} />
                    </div>
                    :
                    <div>
                        <div className='row p-12px'>
                            <div className="col-md-12 justify-content-between align-item-center">
                                <div className='document-innercard-content-title common-text-color mb-3'>{'Attendance'}</div>
                                <div className='mt-15 row' style={{gap:6}}>
                                 { !this.state.is_LWOP_service_Unavailable &&  <CommonButton
                                        label="LWOP REPORT"
                                        onClick={this.handleLwopButtonClick}
                                        isGradientBtn={true}
                                    />}
                                    <CommonButton
                                        label="GENERATE ATTENDANCE REPORT"
                                        onClick={this.handleButtonClick}
                                        isGradientBtn={true}
                                    />
                                </div>
                            </div>
                            <div className='col-md-12 attendance-card'>
                                <BigCalendar
                                    loginData={this.props.loginData}
                                />
                                <div className='row justify-content-center my-27px'>
                                    {leaveDataColor.map((list, id) =>
                                        <div className='row align-item-center' key={id}>
                                            <div className='attendance-color-circle' style={{ backgroundColor: list.color }}></div>
                                            <div className='attendance-leave-text'>{list.type}</div>
                                            <div className='attendance-separator'></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {this.state.isActive &&
                            <DynamicCustomPopupModal
                                loading={this.state.isActive}
                                note_title={this.state.note_title}
                                note_content={this.state.note_content}
                                note_type={this.state.note_type}
                                image_url={this.state.image_url}
                                image_hyperlink={this.state.image_hyperlink}
                                message={this.state.message}
                                onDismiss={this.hideModal} />
                        }
                        {isAttendanceReportPopup &&
                            <DateFilterPopup
                                title={'Download Attendance report'}
                                action={'Report'}
                                onDismiss={this.handleButtonClick}
                            />
                        }
                        {isAttendanceLwopPopup &&
                            <LwopReport
                                title={'Download LWOP Report'}
                                action={'Report'}
                                onDismiss={this.handleLwopButtonClick}
                            />
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        homeActions: bindActionCreators(homeActions, dispatch)
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(AttendanceSheet);