import React, { Component, Fragment } from 'react';
import '../../../assets/styles/styles.css';
import logo from '../../../assets/drishti_images/sidebar/tata_logo.svg';
import { connect } from 'react-redux';
import './Sidebar.css';
import { NavImageList, NavOperativeImageList } from './Sidebar_image';
import OutsideClickHandler from 'react-outside-click-handler';
import { configStore } from "../../../redux/ConfigStore";
import { Link } from 'react-router-dom';
import { BASE_WEB_URL_DEV } from '../../../constants/Config';
import { bindActionCreators } from 'redux';
import * as registrationAction from '../../ScreenType/adminScreens/blueCollarRegistration/RegistrationAction';
class Newsidebar extends Component {
    constructor() {
        super();
        this.state = {
            NavImageList: NavImageList(),
            uniqueArrayState: []
        }
    }

    componentDidMount() {
        if (window.location.href === BASE_WEB_URL_DEV + 'admin/registrationinfo') {
            this.props.actions.adminRegistration("");
        }
        let uniqueArray = this.state.NavImageList.filter((value, index, self) => {
            return (
                index ===
                self.findIndex((item) => item.path === value.path)
            );
        });
        this.setState({
            uniqueArrayState: uniqueArray
        })
    }

    render() {
        const { showMenu } = this.props;
        const isAdminLoggedIn = configStore.getState().adminLoginState.isAdminLoggedIn
        return (
            <>
                {showMenu ?
                    <>
                        <OutsideClickHandler
                            onOutsideClick={() => this.props.onDismiss()}>
                            <div className="mobileSidebar" >
                                <div className="mt-15px white-color">
                                    <label className="ml-10 text21" >DRISHTI 2.0</label>
                                    <button type="button" className="close crossIcon mr-15" onClick={this.props.onDismiss}>&times;</button>
                                </div>
                                <div className='grey-nav'>
                                    <label className="ml-10 text22" >Hello,</label>
                                </div>
                                <div className='white-color'>
                                    <label className="ml-10 text26" >{this.props.loginData.userData.Firstname}!</label>
                                </div>
                                
                                <div className="containerpadding">
                                    <div className='inside-div-list mt-2'>
                                        {NavImageList().map(({ id, src_active, title, src_inactive, description, path }) =>
                                            <>
                                                <div key={id} className='flex-direction-row mt-17px ml-10'>
                                                    <Link to={path} data-tooltip={title}>
                                                        <div className='flex-direction-row'>
                                                            <img src={window.location.pathname === path ?
                                                                src_active :
                                                                src_inactive} alt={description} className={window.location.pathname === path ?
                                                                    "navicon-active" : "navicon"}
                                                            />
                                                            <div className='ml-12 white-color text16_light align-item-center'>{title}</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className='mlrt-10px hrLineNavBar'>
                                                </div>
                                            </>
                                        )}
                                        {/* // # Commented for temporary  */}

                                        {/* {(this.props.loginData.ujrData?.status_code !== '500' || this.props.loginData.ujrData?.ujr_code !== undefined) && isAdminLoggedIn === false &&
                                            <div className='flex-direction-row mt-17px ml-10'>
                                                <Link to={'/learninglandscape'}>
                                                    <div className='flex-direction-row'>
                                                        <img src={window.location.pathname === '/learninglandscape' ? require('../../../assets/drishti_images/sidebar/la-active.svg') : require('../../../assets/drishti_images/sidebar/la-inactive.svg')} alt={'learninglandscape'} className={window.location.pathname === '/learninglandscape' ? "navicon-active" : "navicon"} />
                                                        <div class="ml-12 white-color text16_light align-item-center">Learning Landscape</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        } */}
                                        {(this.props.loginData?.OTData?.is_manager == true || this.props.loginData?.OTData?.is_supervisor == true) && isAdminLoggedIn === false &&
                                            <div className='flex-direction-row mt-17px ml-10'>
                                                <Link to={'/overTime'}>
                                                    <div className='flex-direction-row'>
                                                        <img src={window.location.pathname === '/overTime' ? require('../../../assets/drishti_images/sidebar/activeOT.svg') : require('../../../assets/drishti_images/sidebar/inactiveOT.svg')} alt={'/overtime'} className={window.location.pathname === '/overTime' ? "navicon-active" : "navicon"} />
                                                        <div class="ml-12 white-color text16_light align-item-center">Over time</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        }
                                        <div className='mlrt-10px hrLineNavBar'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OutsideClickHandler>
                    </>
                    :
                    <div className="side-body justify-content-center sidebardesktophide">
                        <div className="containerpadding">
                            <div className='inside-div-list'>
                                <ul>
                                    {isAdminLoggedIn ?
                                        <Link to={'/admin/home'}> <img alt="Logo" src={logo} className="tata-logo"></img></Link>
                                        :
                                        <Link to={'/home'}> <img alt="Logo" src={logo} className="tata-logo"></img></Link>
                                    }
                                    {this.state.uniqueArrayState != undefined && this.state.uniqueArrayState.map(({ id, src_active, title, src_inactive, description, path }, index) =>
                                        <Fragment key={index} >
                                            <Link to={path} data-tooltip={title} className="tabLabel">
                                                <img src={window.location.pathname === path ?
                                                    src_active : src_inactive} alt={description}
                                                    className={window.location.pathname === path ? "navicon-active" : "navicon"} />
                                            </Link>
                                        </Fragment>
                                    )}

                                    {/* // # Commented for temporary  */}

                                    {/* {(this.props.loginData.ujrData?.status_code !== '500' || this.props.loginData.ujrData?.ujr_code !== undefined) && isAdminLoggedIn === false &&
                                        <>
                                            <Link to={'/learninglandscape'} data-tooltip={'Learning Landscape'} className="tabLabel">
                                                <img src={window.location.pathname === '/learninglandscape' ? require('../../../assets/drishti_images/sidebar/la-active.svg') : require('../../../assets/drishti_images/sidebar/la-inactive.svg')} alt={'learninglandscape'} className={window.location.pathname === '/learninglandscape' ? "navicon-active" : "navicon"} />
                                            </Link>
                                        </>
                                    } */}
                                    {(this.props.loginData?.OTData?.is_manager == true || this.props.loginData?.OTData?.is_supervisor == true) && isAdminLoggedIn === false &&
                                        <>
                                            <Link to={'/overTime'} data-tooltip={'Over Time'} className="tabLabel">
                                                <img src={window.location.pathname === '/overTime' ? require('../../../assets/drishti_images/sidebar/activeOT.svg') : require('../../../assets/drishti_images/sidebar/inactiveOT.svg')} alt={'Over Time'} className={window.location.pathname === '/overTime' ? "navicon-active" : "navicon"} />
                                            </Link>
                                        </>
                                    }
                                    {
                                        NavImageList().filter(item => item.title === "Notifications").length === 0 &&
                                        (configStore.getState().adminLoginState.operativeRole?.roles != undefined && configStore.getState().adminLoginState.operativeRole?.roles === "opr-admin"
                                            ||
                                            configStore.getState().adminLoginState.operativeRole?.roles != undefined && configStore.getState().adminLoginState.operativeRole?.roles === "hr-notification-plant-spoc"
                                            ||
                                            configStore.getState().adminLoginState.operativeRole?.roles != undefined && configStore.getState().adminLoginState.operativeRole?.roles === "hr-notification-corp-admin"
                                        )
                                        &&
                                        <Fragment  >
                                            <Link to={'/admin/notification/home'} data-tooltip={'Notifications'} className="tabLabel">
                                                <img src={window.location.pathname === '/admin/notification/home' ?
                                                    require('../../../assets/drishti_images/sidebar/admin-notification-active.svg') :
                                                    require('../../../assets/drishti_images/sidebar/admin-notification-inactive.svg')}
                                                    alt={'adminNotificationScreen'}
                                                    className={window.location.pathname === '/admin/notification/home' ? "navicon-active" : "navicon"}
                                                />
                                            </Link>
                                        </Fragment>
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(registrationAction, dispatch),
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(Newsidebar);