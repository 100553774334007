import React, { Component } from 'react'
import back from '../../../../../assets/drishti_images/back.svg';
import dustbeen from '../../../../../assets/drishti_images/todo/delete.svg';
import nodataicon from '../../../../../assets/drishti_images/nodatafound/todolist-nodata.svg';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import moment from 'moment';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import ConfirmPopup from '../../../employeeScreens/todo/ConfirmPopup'
import AddNewTask from '../../../employeeScreens/todo/AddNewTask'
import tag from '../../../../../assets/drishti_images/todo/tag.svg'
import Greentag from '../../../../../assets/drishti_images/todo/Greentag.svg'
import orangeTag from '../../../../../assets/drishti_images/todo/orangeTag.svg'
import task_calender from '../../../../../assets/drishti_images/todo/task_calender.svg'
import { TODoListSkeleton } from '../../../../shared/skeleton/Skeletons';
class ManagerTodoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            todoSelect: 'Organisation',
            todoData: [],
            loading: false,
            update: false,
            delete: false,
            add: false,
            selectedData: {},
            dueDateFilter: [],
            statusKey: ["notStarted"],
            filterType: ["status", "task_due_date"],
            priorityey: [],
            approved: false
        }
    }

    componentDidMount() {
        // this.createAdminTask() # Commented for temporary 
    }

    createAdminTask = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.loginData.userData.CompCode,
            emp_id: this.props.loginData.userData.Perno,
        }
        fetchApiCall(Config.createAdminTask, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    this.getTODOData()
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }

    getTODOData = () => {
        // # Commented for temporary 

        // this.setState({
        //     loading: true
        // });
        // const headers = {
        //     'Content-Type': 'application/json',
        // }
        // let body = {
        //     emp_id: this.props.loginData.userData.Perno,
        //     todo_display_name: this.state.todoSelect,    //'Tasks
        //     filter_type: this.state.filterType,         //["status","task_due_date"], 
        //     status_key: this.state.statusKey,           //["notStarted"],
        //     priority_key: this.state.priorityey,       //[],
        //     due_date_filter: this.state.dueDateFilter   //["earlier"]
        // }
        // fetchApiCall(Config.getAllTODOTasks, body, headers, true, '')
        //     .then(res => {
        //         // console.log('getAllTODOTasks', res);
        //         sessionOut(this.props, res);
        //         if (res.data.status_code == 200) {
        //             this.setState({
        //                 todoData: res.data.personal_tasks ? res.data.personal_tasks : [],
        //                 loading: false,
        //             });
        //             const tempTodoList = this.state.todoData.map(tempValue => ({ ...tempValue, isCompleted: tempValue.status === 'completed' ? true : false }))
        //             this.setState({ todoData: tempTodoList })

        //         } else {
        //             this.setState({
        //                 loading: false,
        //                 todoData: []
        //             });
        //         }
        //     }).catch(err => {
        //         this.setState({
        //             loading: false,
        //             todoData: []
        //         });
        //     })
    }

    deleteTask = (data) => {
        this.setState({
            loading: true, delete: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            todo_display_name: this.state.todoSelect,
            task_id: data.id,
            comp_code: this.props.loginData.managerData.CompCode
        }
        fetchApiCall(Config.deleteMSTodoTasks, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        todoData: res.data.personal_tasks ? res.data.personal_tasks : [],
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getTODOData()
                } else {
                    this.setState({
                        loading: false,
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }

    updateAction = (type, data) => {
        if (type === 'delete') {
            this.setState({ delete: true, selectedData: data })
        }
        else if (type === 'update') {
            this.setState({ approved: true, selectedData: data })
        }
        else {
            this.setState({ add: true })
        }
    }

    goToScreen = () => {
        window.location = (BASE_WEB_URL_DEV + 'todo');
    }

    updateTaskComplete = (data) => {
        if (data.status === 'completed') return null;
        this.setState({
            loading: true,
            approved: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            todo_display_name: this.state.todoSelect,
            title: data.title,
            task_due_date: data.dueDateTime ? data.dueDateTime.dateTime : '',
            priority: data.importance,
            status: 'completed',
            task_id: data.id,
            comp_code: this.props.loginData.managerData.CompCode
        }
        fetchApiCall(Config.updateMSTodoTasks, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getTODOData()
                } else {
                    this.setState({
                        loading: false,
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    toggleTask = (type) => {
        if (type === 'Tasks') {
            this.setState({ todoSelect: type }, () => this.getTODOData())
        }
        else {
            this.setState({ todoSelect: type }, () => this.getTODOData())
        }

    }

    render() {
        const { loading } = this.state
        return (
            <div className="card-new h-100 ">
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">{'To-Do List'}</label>
                        </div>
                        <div onClick={this.goToScreen}>
                            <img alt="Back" src={back} className="righticon"></img>
                        </div>
                    </div>
                </div>
                {this.state.todoSelect === 'Organisation' ?
                    <div className="row d-flex justify-content-center mt-2 mb-1 p-lr">
                        <div className="row activate-button-left d-flex justify-content-center">{'Org'}</div>
                        <div className="row deactivate-button-right d-flex justify-content-center" onClick={() => this.toggleTask('Tasks')}>{'Personal'}</div>
                    </div> :
                    <div className="row d-flex justify-content-center mt-2 mb-1 p-lr" >
                        <div className="row deactivate-button-left d-flex justify-content-center" onClick={() => this.toggleTask('Organisation')}>{'Org'}</div>
                        <div className="row activate-button-right d-flex justify-content-center">{'Personal'}</div>
                    </div>
                }
                {loading ?
                    <>
                        <TODoListSkeleton />
                    </>
                    :
                    <>
                        {this.state.todoData.length != 0 ?
                            <>
                                {this.state.todoSelect === 'Tasks' ?

                                    <>
                                        <div className='scroll-x mLRT_Manager'>
                                            <div className='m-10px scroll-webkit-x ml-4 mr-4'>
                                                {this.state.todoData.map((todolist, id) =>
                                                     <div key={id} className='card-new mt-1 mr-2'>
                                                     <div className='row p-2 pr-2'>
                                                            <div style={{ flex: 1 / 5 }}>
                                                                <label className="labell">
                                                                    <input type="checkbox" name="radio"
                                                                        checked={todolist.isCompleted}
                                                                        disabled={todolist.isCompleted}
                                                                        onChange={() => this.updateAction('update', todolist)} />
                                                                    <span className="checkk"></span>
                                                                </label>
                                                            </div>
                                                            <div style={{ flex: 1 }}>
                                                                <div className="to-do-list" >
                                                                    <div className='justify-content-between' style={{ flex: 3 }}>
                                                                        <div className='row todotitle'>
                                                                            {todolist.title}
                                                                        </div>
                                                                        <div className='ml-1'>
                                                                            <img alt='' src={todolist.importance === 'high' ? tag : todolist.importance === 'normal' ? orangeTag : Greentag} style={{ height: 17 }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='justify-content-between'>
                                                                        <div className='row align-item-center mt-2'>
                                                                            <img alt='check' src={task_calender} className='check-icon mr-2' />
                                                                            <div className='tododate'>Due date:
                                                                                {todolist.dueDateTime ? moment(todolist.dueDateTime.dateTime).format('  DD MMMM, YYYY') : '  Not Setted  '}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <img alt='delete' src={dustbeen} data-toggle='modal' style={{}}
                                                                                data-target='#DeleteConfirmationModal' onClick={() => this.updateAction('delete', todolist)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className='scroll-x mLRT_Manager'>
                                        <div className='m-10px scroll-webkit-x ml-4 mr-4'>
                                            {this.state.todoData.map((todolist, id) =>
                                                <div key={id} className='card-new mt-1 mr-2'>
                                                    <div className='row p-2 pr-2'>
                                                        <div style={{ flex: 1 / 5 }}>
                                                            <label className="labell">
                                                                <input type="checkbox" name="radio"
                                                                    checked={todolist.isCompleted}
                                                                    disabled={todolist.isCompleted}
                                                                    onChange={() => this.updateAction('update', todolist)} />
                                                                <span className="checkk"></span>
                                                            </label>
                                                        </div>
                                                        <div style={{ flex: 1 }}>
                                                            <div className="to-do-list">
                                                                <div className='flex-direction-column' style={{ flex: 3 }}>
                                                                    <div className='row justify-content-between'>
                                                                        <div className='todotitle width-85'>
                                                                            {todolist.title}
                                                                        </div>
                                                                        <div className='ml-1 '>
                                                                            <img alt='' src={todolist.importance === 'high' ? tag : todolist.importance === 'normal' ? orangeTag : Greentag} style={{ height: 17 }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='justify-content-between'>
                                                                    <div className='row align-item-center mt-2'>
                                                                        <img alt='check' src={task_calender} className='check-icon mr-2' />
                                                                        <div className='tododate'>Due date:
                                                                            {todolist.dueDateTime ? moment(todolist.dueDateTime.dateTime).format('  DD MMMM, YYYY') : '  Not Setted  '}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <div className='nodata-incenter mt_52'>
                                <img src={nodataicon} alt="Nodata"/>
                                <label className='card-content-title grey-color'>{'No tasks yet!'}</label>
                                {this.state.todoSelect === 'Tasks' &&
                                    <div onClick={() => this.updateAction('add', '')} >
                                        <label className='card-content-title purple-color addnewtodo'>{'Add new task'}</label>
                                    </div>}
                            </div>
                        }
                    </>
                }
                {
                    this.state.delete &&
                    <ConfirmPopup
                        onDismiss={() => this.setState({ delete: false, selectedData: {} })}
                        deleteData={() => this.deleteTask(this.state.selectedData)}
                        taskTitle={this.state.selectedData.title}
                    />
                }
                {
                    this.state.approved &&
                    <ConfirmPopup
                        onDismiss={() => this.setState({ approved: false, selectedData: {} })}
                        approve={() => this.updateTaskComplete(this.state.selectedData)}
                        taskTitle={this.state.selectedData.title}
                        type={'Approve'}
                    />
                }
                {
                    this.state.update ?
                        <AddNewTask
                            loginData={this.props.loginData}
                            type={this.state.todoSelect}
                            action={'update'}
                            data={this.state.selectedData}
                            onDismiss={() => this.setState({ update: false }, () => this.getTODOData())}
                        /> : null
                }
                {
                    this.state.add ?
                        <AddNewTask
                            loginData={this.props.loginData}
                            type={this.state.todoSelect}
                            action={'add'}
                            onDismiss={() => this.setState({ add: false }, () => this.getTODOData())}
                        /> : null
                }
            </div >
        )
    }
}

export default ManagerTodoList;