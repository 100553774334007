import React, { Component, useState } from "react";
import { suggestionMasterScreen } from "./constant";
import moment from "moment";
import { connect } from "react-redux";
import view_action from "../../../../../assets/drishti_images/transfer/view_action.svg";
import edit from "../../../../../assets/images/cfm/edit.svg";
import delete_action from "../../../../../assets/drishti_images/transfer/delete_action.svg";
import DataNotFound from "../../../../shared/notFound/DataNotFound";
import ConfirmationPopUp from "../../clearance/ConfirmationPopUp";

function MasterScreenDashboard({ tabName, masterScreenListData, onAction }) {
  const [showDelete,setShowDelete] = useState(false);
  const [currentVal,setCurrentVal] = useState(null);
  return (
    <>
      <div className="table-container">
        <div className="main-contain">
          <div className="row align-item-center justify-content-start ">
          {
            (masterScreenListData?.length !== 0) ? 
            <>
             
              <table className="table gemsAdmin">
                <thead className="w-100">
                  <tr className="w-100">
                    {
                      <th width="5%">
                        <div className="thead">{suggestionMasterScreen.Sr}</div>
                      </th>
                    }
                    {/* scheme list */}
                    {tabName.key == suggestionMasterScreen.SchemeList && (
                      <th width="40%">
                        <div className="thead">
                          {suggestionMasterScreen.SchemeName}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.SchemeList && (
                      <th width="15%">
                        <div className="thead">
                          {suggestionMasterScreen.StartDate}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.SchemeList && (
                      <th width="15%">
                        <div className="thead">
                          {suggestionMasterScreen.EndDate}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.SchemeList && (
                       <th width="15%">
                       {/* <div className="thead">Image View</div> */}
                       <div className="thead">
                         {suggestionMasterScreen.Image}
                       </div>
                     </th>
                    )}

                    {/* Division list */}
                    {tabName.key == suggestionMasterScreen.DivisionList && (
                      <th width="40%">
                        <div className="thead">
                          {suggestionMasterScreen.DivisionName}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.DivisionList && (
                      <th width="30%">
                        <div className="thead">
                          {suggestionMasterScreen.DivisionCode}
                        </div>
                      </th>
                    )}

                    {/* Agency list */}
                     {tabName.key == suggestionMasterScreen.AgencyList && (
                      <th width="25%">
                        <div className="thead">
                          {suggestionMasterScreen.AgencyName}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.AgencyList && (
                      <th width="15%">
                        <div className="thead">
                          {suggestionMasterScreen.AgencyCode}
                        </div>
                      </th>
                    )}
                     {tabName.key == suggestionMasterScreen.AgencyList && (
                      <th width="25%">
                        <div className="thead">
                          {suggestionMasterScreen.DivisionName}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.AgencyList && (
                      <th width="15%">
                        <div className="thead">
                          {suggestionMasterScreen.DivisionCode}
                        </div>
                      </th>
                    )}


                    {/* Evaluator list */}
                    {(tabName.key == suggestionMasterScreen.Evaluator || tabName.key == suggestionMasterScreen.DivisionCordinator) && (
                      <th width="20%">
                        <div className="thead">
                          {suggestionMasterScreen.EmployeeId}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.Evaluator && (
                      <th width="25%">
                        <div className="thead">
                          {suggestionMasterScreen.AgencyName}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.Evaluator && (
                      <th width="15%">
                        <div className="thead">
                          {suggestionMasterScreen.AgencyCode}
                        </div>
                      </th>
                    )}
                    {(tabName.key == suggestionMasterScreen.Evaluator || tabName.key == suggestionMasterScreen.DivisionCordinator) && (
                      <th width="25%">
                        <div className="thead">
                          {suggestionMasterScreen.DivisionName}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.DivisionCordinator && (
                      <th width="15%">
                        <div className="thead">
                          {suggestionMasterScreen.DivisionCode}
                        </div>
                      </th>
                    )}

                    {/* Cost Center list */}
                    {tabName.key == suggestionMasterScreen.CostCenter && (
                    <th width="15%">
                      <div className="thead">
                        {suggestionMasterScreen.CostCenterNo}
                      </div>
                    </th>
                    )}
                    {tabName.key == suggestionMasterScreen.CostCenter  && (
                      <th width="25%">
                        <div className="thead">
                          {suggestionMasterScreen.CostCenterName}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.CostCenter && (
                      <th width="10%">
                        <div className="thead">
                          {suggestionMasterScreen.pa}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.CostCenter && (
                      <th width="10%">
                        <div className="thead">
                          {suggestionMasterScreen.psa}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.CostCenter && (
                      <th width="10%">
                        <div className="thead">
                          {suggestionMasterScreen.compcode}
                        </div>
                      </th>
                    )}
                    {tabName.key == suggestionMasterScreen.CostCenter && (
                      <th width="15%">
                        <div className="thead">
                          {suggestionMasterScreen.DivisionCode}
                        </div>
                      </th>
                    )}
                    {
                      <th width="10%">
                        <div className="thead">Action</div>
                      </th>
                    }
                  </tr>
                </thead>
                <tbody className="adminScreentbody w-100">
                  {
                  
                    masterScreenListData?.map((listValue, index) => {
                      return (
                        <tr key={index}>
                          <td width="5%">
                            {" "}
                            <div className="td-choice-pay d-flex amount-justify-content-start">
                              {index + 1}
                            </div>{" "}
                          </td>

                          {/* Scheme List */}
                          {tabName.key == suggestionMasterScreen.SchemeList && (
                            <td width="40%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.scheme_name
                                  ? listValue.scheme_name
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.SchemeList && (
                            <td width="15%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.start_date ? moment(listValue.start_date).format('DD-MM-YYYY') : "-" }
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.SchemeList && (
                            <td width="15%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.end_date ? moment(listValue.end_date).format('DD-MM-YYYY') : "-" }
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.SchemeList && (
                            <td width="15%">
                             <div onClick={() => onAction(listValue, "view")}>
                               <img
                                 alt="view"
                                 src={view_action}
                                 style={{
                                   width: "25px",
                                   cursor: "pointer",
                                   marginRight: "10px",
                                 }}
                               />
                             </div>
                            </td>
                          )}

                          {/* Division List */}
                          {tabName.key == suggestionMasterScreen.DivisionList && (
                            <td width="40%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.division_name
                                  ? listValue.division_name
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.DivisionList && (
                            <td width="30%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.division_code ? listValue.division_code : "-" }
                              </div>
                            </td>
                          )}


                          {/* Agency List */}
                          {tabName.key == suggestionMasterScreen.AgencyList && (
                            <td width="25%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.agency_name
                                  ? listValue.agency_name
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.AgencyList && (
                            <td width="15%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.agency_code ? listValue.agency_code : "-" }
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.AgencyList && (
                            <td width="25%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.division_name
                                  ? listValue.division_name
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.AgencyList && (
                            <td width="15%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.division_code ? listValue.division_code : "-" }
                              </div>
                            </td>
                          )}

                          {/* Evaluator */}
                          {(tabName.key == suggestionMasterScreen.Evaluator || tabName.key == suggestionMasterScreen.DivisionCordinator) && (
                            <td width="20%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.emp_id
                                  ? listValue.emp_id
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.Evaluator && (
                            <td width="25%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.agency_name
                                  ? listValue.agency_name
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.Evaluator && (
                            <td width="15%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.agency_id
                                  ? listValue.agency_id
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {(tabName.key == suggestionMasterScreen.Evaluator || tabName.key == suggestionMasterScreen.DivisionCordinator) && (
                            <td width="25%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.division_name ? listValue.division_name : "-" }
                              </div>
                            </td>
                          )}
                           {(tabName.key == suggestionMasterScreen.DivisionCordinator) && (
                            <td width="25%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.division_id ? listValue.division_id : "-" }
                              </div>
                            </td>
                          )}

                          {/* Cost Center List */}
                          {tabName.key == suggestionMasterScreen.CostCenter && (
                            <td width="15%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.cost_center_no
                                  ? listValue.cost_center_no
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.CostCenter && (
                            <td width="25%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.cost_center_name ? listValue.cost_center_name : "-" }
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.CostCenter && (
                            <td width="10%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                {listValue.pa
                                  ? listValue.pa
                                  : "-"}
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.CostCenter && (
                            <td width="10%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.psa ? listValue.psa : "-" }
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.CostCenter && (
                            <td width="10%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.comp_code ? listValue.comp_code : "-" }
                              </div>
                            </td>
                          )}
                          {tabName.key == suggestionMasterScreen.CostCenter && (
                            <td width="15%">
                              <div className="td-choice-pay d-flex amount-justify-content-start">
                                { listValue.division ? listValue.division : "-" }
                              </div>
                            </td>
                          )}
                          
                          <td width="10%">
                            <span className="d-flex">
                              {(tabName.key != suggestionMasterScreen.Evaluator && tabName.key != suggestionMasterScreen.DivisionCordinator && tabName.key != suggestionMasterScreen.CostCenter) ? (
                                <div onClick={() => onAction(listValue, "update")}>
                                  <img
                                    alt="update"
                                    src={edit}
                                    style={{
                                      width: "25px",
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                  />
                                </div>
                              ) : null }
                              <div onClick={() => { setShowDelete(true); setCurrentVal(listValue) } }>
                                <img
                                  alt="delete"
                                  src={delete_action}
                                  style={{ width: "25px", cursor: "pointer" }}
                                />
                              </div>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  
                  }
                </tbody>
              </table>
            </>
            :
            <>
              <div className="grid-center">
                <DataNotFound />
              </div>
            </>
          }
          { showDelete &&
            <ConfirmationPopUp onDismiss={() => {
              setShowDelete(false)
            }} title={"Confirmation"} loading={false} onConfirmAction={() => {
              onAction(currentVal, "delete")
              setShowDelete(false)
            }} message={"Are you sure you want to delete this item?"} />
          }
          </div>
        </div>
      </div>
    </>
  );
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
  };
};

export default connect(mapStatesToProps)(MasterScreenDashboard);
