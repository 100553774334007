import React, { Component } from 'react';
import NewSidebar from '../components/shared/sidebar/Sidebar';
import NewHeader from '../components/shared/header/Header';
import NewHomeScreen from '../components/ScreenType/employeeScreens/landingScreen/home/Home';
import DocumentScreen from '../components/ScreenType/employeeScreens/documents/DocumentScreen';
import LeaveScreen from '../components/ScreenType/employeeScreens/leave/LeaveScreen';
import CFMHome from '../components/ScreenType/employeeScreens/continuousFeedbackManagement/CFMHome';
import ProfileScreen from '../components/ScreenType/employeeScreens/profile/ProfileScreen';
import HomeScreenCOI from '../components/ScreenType/employeeScreens/conflictOfInterest/HomeScreenCOI';
import AttendanceSheet from '../components/ScreenType/employeeScreens/attendance/AttendanceSheet';
import AnnouncmentHomescreen from '../components/ScreenType/employeeScreens/Announcement/announcementScreen';
import ToDo from '../components/ScreenType/employeeScreens/todo/ToDo';
import './RouteComponentStyle.css';
import { Redirect } from 'react-router-dom';
import ManagerLandingScreen from '../components/ScreenType/managerScreens/landing/ManagerLandingScreen';
import TeamsHomeScreen from '../components/ScreenType/managerScreens/teams/TeamsHomeScreen';
import ManagerApprovalScreen from '../components/ScreenType/managerScreens/approval/ManagerApprovalScreen';
import Transfer from '../components/ScreenType/employeeScreens/transfer/requestForms/Transfer';
import TransferScreen from '../components/ScreenType/employeeScreens/transfer/approval/ApprovalHome';
import ExitForm from '../components/ScreenType/employeeScreens/exitform/ExitForm';
import DirectReporteeDetail from '../components/ScreenType/managerScreens/teams/directReportees/DirectReporteeDetail';
import { configStore } from "../redux/ConfigStore";
import AdminLanding from '../components/ScreenType/adminScreens/landing/AdminLanding'; //<---New Admin Landing
import AdminHomeScreen from '../components/ScreenType/adminScreens/adminNotificationHome/AdminHomeScreen';
import TodoScreen from '../components/ScreenType/adminScreens/todoScreens/TodoScreen';
import TransferAdmin from '../components/ScreenType/adminScreens/transferScreens/requestForms/TransferAdmin';
import AdminTransferScreen from '../components/ScreenType/adminScreens/transferScreens/approval/ApprovalHome';
import AdminExitScreen from '../components/ScreenType/adminScreens/exitScreens/ExitScreen';
import AdminleaveScreen from '../components/ScreenType/adminScreens/leave/LeaveScreen';
import NotificationDetails from '../components/ScreenType/employeeScreens/notification/NotificationDetails';
import Learningarchitecture from '../components/ScreenType/employeeScreens/LearningArchitecture/learningArchitectureHome';
import COIDashboard from '../components/ScreenType/adminScreens/coi/COIDashboard';
import DevAdminMain from '../components/ScreenType/adminScreens/developerTools/DevAdminMain';
import EnableDisableMain from '../components/ScreenType/adminScreens/screenEnableDisable/EnableDisableMain';
import NotificationsHome from '../components/ScreenType/adminScreens/adminNotifications/NotificationsHome';
import ESeparationHome from '../components/ScreenType/employeeScreens/eSeparation/ESeparationHome';
import MOMHomeScreen from '../components/ScreenType/employeeScreens/momentsThatMatter/MOMHomeScreen';
// import RegistrationForm from '../components/ScreenType/adminScreens/blueCollarRegistration/RegistrationForm';
import ViewRegisterInfo from '../components/ScreenType/adminScreens/blueCollarRegistration/ViewRegisterInfo';
import AnnouncementNotification from '../components/ScreenType/adminScreens/announcementNotification/AnnouncementNotification';
import EseparationDashboard from '../components/ScreenType/employeeScreens/eSeparation/manager/EseparationDashboard';
import EsepTrackerDashboard from '../components/ScreenType/employeeScreens/eSeparation/manager/EsepTrackerDashboard';
import EseparationAdminHome from '../components/ScreenType/adminScreens/eSeparation/EseparationAdminHome';
import PaySpaceHome from '../components/ScreenType/employeeScreens/paySpace/PaySpaceHome';
import GEMSHomeScreen from '../components/ScreenType/employeeScreens/gems/HomeScreen';
import GEMSAdminHomeScreen from '../components/ScreenType/adminScreens/gems/GEMSAdminHomeScreen';
import LocalConveyanceHomeScreen from '../components/ScreenType/adminScreens/localConveyance/LocalConveyanceHomeScreen';
import OTDashboard from '../components/ScreenType/employeeScreens/overTime/Dashboard/OTDashboard';
import OTAdminDashboard from '../components/ScreenType/adminScreens/overtimeBooking/OTAdminDashboard';
import ClearanceAdminHome from '../components/ScreenType/adminScreens/clearance/ClearanceAdminHome';
import SuggestionSchemeHome from '../components/ScreenType/employeeScreens/suggestionScheme/SuggestionSchemeHome';
import SuggestionOfficeHome from '../components/ScreenType/adminScreens/suggestionScheme/suggestionOffice/SuggestionOfficeHome';
import SuggestionSchemeHomeAdmin from '../components/ScreenType/adminScreens/suggestionScheme/suggestionSchemeAdmin';
import EHackHome from '../components/ScreenType/employeeScreens/ehack/EHackHome';

class RouteComponent extends Component {
    constructor(props) {
        super(props);
    }

    routeChange = (ComponentToPassDown) => {
        switch (ComponentToPassDown) {
            case 'home':
                return <NewHomeScreen />
            case 'document':
                return <DocumentScreen />
            case 'cfm':
                return <CFMHome />
            case 'coi':
                return <HomeScreenCOI />
            case 'profile':
                return <ProfileScreen />
            case 'leave':
                return <LeaveScreen />
            case 'attendance':
                return <AttendanceSheet />
            case 'announcement':
                return <AnnouncmentHomescreen />
            case 'todo':
                return <ToDo />
            case 'transferRequest':
                return <Transfer />
            case 'momentsThatMatter':
                return <MOMHomeScreen />
            case 'transfer':
                return <TransferScreen />
            case 'exit':
                return <ExitForm />
            case 'esepRequest':
                return <ESeparationHome />
            case 'esepRequest':
                return <EseparationDashboard />
            case 'esepRequestTracker':
                return <EsepTrackerDashboard />
            case 'directReporteeDetail':
                return <DirectReporteeDetail />
            case 'managerApproval':
                return <ManagerApprovalScreen />
            case 'managerTeams':
                return <TeamsHomeScreen />
            case 'AdminLanding':
                return <AdminLanding />
            // case 'adminNotification':
            //     return <AdminHomeScreen />
            case 'adminNotificationScreen':
                return <NotificationsHome />
            case 'adminLeave':
                return <AdminleaveScreen />
            case 'adminTodo':
                return <TodoScreen />
            case 'adminTransfer':
                return <AdminTransferScreen />
            case 'transferAdminRequest':
                return <TransferAdmin />
            case 'adminExit':
                return <AdminExitScreen />
            case 'adminEseparation':
                return <EseparationAdminHome />
            case 'adminCoi':
                return <COIDashboard />
            case 'notificationDetails':
                return <NotificationDetails />
            case 'learningArchitecture':
                return <Learningarchitecture />
            case 'devTools':
                return <DevAdminMain />
            case 'screenEnableDisable':
                return <EnableDisableMain />
            // case 'registrationScreen' :
            //     return <RegistrationForm />
            case 'viewRegistrationInfo':
                return <ViewRegisterInfo />
            case 'announcementNotification':
                return <AnnouncementNotification />
            case 'paySpace':
                return <PaySpaceHome />
            case 'GEMSHomeScreen':
                return <GEMSHomeScreen />
            case 'adminGEMSHomeScreen':
                return <GEMSAdminHomeScreen />
            case 'adminLocalConveyance':
                return <LocalConveyanceHomeScreen />
            case 'overTime':
                return <OTDashboard />
            case 'adminOvertime':
                return <OTAdminDashboard/>
            case 'adminClearance':
                return <ClearanceAdminHome />
            case 'employeeSuggestionScheme':
                return <SuggestionSchemeHome />
            case 'adminSuggestionScheme':
                return <SuggestionSchemeHomeAdmin />
            case 'eHackHome':
                return <EHackHome/>
            default:
                return null
        }
    }

    render() {
        const isManager = configStore.getState().keycloakState.keycloakData.is_employee_reporting
        const isSupervisor = configStore.getState().loginState?.OTData?.is_trainee_approver
        const tranieeApprovalActive = configStore.getState()?.activePopupState?.availableServiceData?.some(service => service?.['service_type'] == 'trl_leave_approval' && service?.['is_active'] == true) || false;
        const { ComponentToPassDown, show, isAdmin } = this.props
        return (
            <div>
                {show ?
                    <div className='mainView'>
                        <div className='childOne' >
                            <NewSidebar />
                        </div>
                        <div className='childTwo main-content'>
                            <div className='childTwoInnerOne'><NewHeader /></div>
                            <div className='childTwoInnerTwo p-12px'>
                                {ComponentToPassDown === 'home' && (isManager || (isSupervisor && tranieeApprovalActive))?
                                    <ManagerLandingScreen />
                                    :
                                    <>
                                        {this.routeChange(ComponentToPassDown)}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {isAdmin ?
                            <Redirect to={{
                                pathname: '/admin',
                                initialpath: this.props.location
                            }} />
                            :
                            <Redirect to={{
                                pathname: '/login',
                                initialpath: this.props.location
                            }} />
                        }
                    </>
                }
            </div>
        )
    }
}

export default RouteComponent;