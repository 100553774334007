import { Label } from "recharts";

export const masterScreenList = [
    { key: 'SchemeList', value: 'Special Scheme', endPoint: '' },
    { key: 'Reopen', value: 'Re-Open', endPoint: '' },
    { key: 'DivisionList', value: 'Division', endPoint: '' },
    { key: 'AgencyList', value: 'Agency', endPoint: '' },
    { key: 'Evaluator', value: 'Evaluator', endPoint: '' },
    { key: 'DivisionCordinator', value: 'Division Co-ordinator', endPoint: '' },
    { key: 'CostCenter', value: 'Cost Center Mapping', endPoint: '' },
]
 
export const sendToReopen = [
    {id: 0, title: 'Send to Evaluator', value:'evaluator'},
    {id: 1, title: 'Send to Division Co-ordinator', value:'division'},
    {id: 2, title: 'Send to Suggestion office', value:'suggestionOffice'}
]
 
export const suggestionMasterScreen = {
    Sr: 'Sr. No.',
    SchemeName: 'Scheme Name',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    // keys
    SchemeList: 'SchemeList',
    Image: 'Image',
    
    //Division
    DivisionList:'DivisionList',
    DivisionName: 'Division Name',
    DivisionCode: 'Division Code',

    //Agency
    AgencyList:'AgencyList',
    AgencyName: 'Agency Name',
    AgencyCode: 'Agency Code',
    DivisionName: 'Division Name',
    DivisionCode: 'Division Code',

    //Evaluator
    Evaluator:'Evaluator',
    EmployeeId: 'Employee Id',

    //DivisionCordinator
    DivisionCordinator: 'DivisionCordinator',

    //Cost Center Mapping
    CostCenter:'CostCenter',
    CostCenterNo: 'Cost Center No.',
    CostCenterName: 'Cost Center Name',
    pa: 'Person Area',
    psa: 'Person Sub Area',
    compcode: 'Company',
    DivisionName: 'Division Name',

    ReOpen: 'Reopen'

}

export const minImageHeight = 160;
export const maxImageHeight = 250;
export const minImageWidth = 360;
export const maxImageWidth = 450;


