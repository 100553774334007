import React, {useState, useEffect } from "react";
import DynamicDropdown from "../../../../shared/components/DynamicDropdown";
import Dropdown from "../../../../shared/components/Dropdown";
import { toast } from "react-toastify";
import { masterScreenList, suggestionMasterScreen, sendToReopen } from "./constant";
import MasterScreenDashboard from "./MasterScreenDashboard";
import { configStore } from "../../../../../redux/ConfigStore";
import {
  getSuggestionSpecialScheme,
  deleteSuggestionSchemeName,
  getSuggestonOfficeAgencyDashboard,
  getSuggAgency,
  getSugReopenDetails,
  submitReopenSuggestion,
  suggetionOfficerSuggestionById,
  getAgencyAdminMasterScreen,
  deleteAgencyAdminMaster,
  getDivsionalCordinator,
  deleteDivsionalCordinator,
  getEvaluator,
  deleteEvaluator,
  getDivisionAdminMasterScreen,
  deleteDivisionAdminMaster,
  getCostCenterAdminMasterScreen,
  deleteCostCenterAdminMaster,
  getPSAList
} from "../../../../../constants/api.service";
import UpdatePopUpScreen from "./UpdatePopUpScreen";
import searchIcon from "../../../../../assets/drishti_images/admin/searchIcon.svg";
import AddPopUpScreen from "./AddPopUpScreen";
import { toaster } from "../../../../../utils/Utils";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import DataNotFound from "../../../../shared/notFound/DataNotFound";
import { useSelector } from "react-redux";
import SuggestionReOpenDetails from "../suggestionOffice/SuggestionReOpenDetails";
import { Pagination } from "react-bootstrap";

function SuggestionMasterScreen() {
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [updateFormData, setUpdateFormData] = useState(null);
  const [isFormUpdatePopup, setIsFormUpdatePopup] = useState(false);
  const [AddFormData, setAddFormData] = useState(false);
  const [isFormViewPopup, setIsFormViewPopup] = useState(false);
  const [addFormBtn, setAddFormBtn] = useState(true);
  const [masterScreenListData, setMasterScreenListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMasterScreenDashboard, setShowMasterScreenDashboard] =
    useState(false);
  const [showMasterScreenDashboardReopen, setShowMasterScreenDashboardReopen] =
    useState(false);
  const [divisionList, setDivisionList] = useState([]);
  const [suggestionData, setSuggestionData] = useState(null);
  const [divisionCode, setDivisionCode] = useState(null);
  const AdminLoginData = useSelector((state) => state.adminLoginState);
  const [suggestionId, setSuggestionId] = useState("");
  const [search, setSearchFunction] = useState("");
  const [agencyData, setAgencyData] = useState([]);
  const [agency, setAgency] = useState("");
  const [clarificationRemark, setClarificationRemark] = useState("");
  const [showData, setShowData] = useState(false);
  const [selectedReopenStatus, setSelectedReopenStatus] = useState(null);
  const [showReopenDivison,setShowReopenDivison] = useState(false);
  const [showReopenAgency,setShowReopenAgency] = useState(false);
  const [statusCode,setStatusCode] = useState(null)
  const [orginalAgencyDetails,setOrignalAgencyDetails] = useState(null);
  const [selectedDivisionType, setSelectedDivisionType] = useState(null);
  const [showRemarks,setShowRemarks] = useState(false)
  const [selectedStatus,setSelectedStatus] = useState('')
  // const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText,setSearchText] = useState('');
  const [showAddbtn,setShowAddbtn] = useState(false);
  const [showPagination,setShowPagination] = useState(false);

  const getSuggestionByIdData = (sug_id) => {
    setLoading(true)
    suggetionOfficerSuggestionById(sug_id)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setSuggestionData(data?.data);
          setSelectedStatus(data?.data?.suggestion_details?.status)
          setShowData(true)
          
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => { setLoading(false) })
  }

  //---------------------------------Get Suggestion By Id for Reopen cases------------------------//
  const getSuggestionById = async (sug_id) => {
    if (sug_id.trim() === "") {
      toaster("warning", "Suggestion ID cannot be empty");
      setShowData(false);
      setStatusCode(null)
      setShowRemarks(false)
      setClarificationRemark("");
    } else if (!isNaN(sug_id) && !isNaN(parseFloat(sug_id))) {
      setSelectedDivisionType(null);
      setAgency(null);
      setClarificationRemark("");
      setSelectedReopenStatus(null)
      setShowReopenDivison(false);
      setShowReopenAgency(false);
      setShowData(false)
      let params = {
        comp_code: AdminLoginData?.company?.company_code,
        pa: AdminLoginData?.userData?.PersArea,
        sug_id: sug_id,
      };
      await getSugReopenDetails(params)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            if ((res?.data?.data?.suggestion_details?.status_code === 7) || (res?.data?.data?.suggestion_details?.status_code === 8) || (res?.data?.data?.suggestion_details?.status_code === 9)){
              setShowReopenDivison(true)
            }
            setSuggestionData(res?.data?.data);
            setStatusCode(res?.data?.data?.suggestion_details?.status_code)
            setShowRemarks(true)
            setOrignalAgencyDetails({
              id: res?.data?.data?.suggestion_details?.agency,
              label: res?.data?.data?.suggestion_details?.agency_name,
            })
            getAgency(res?.data?.data?.suggestion_details?.division_id);
            setDivisionCode(res?.data?.data?.suggestion_details?.division_id);
            setShowData(true);
            toaster("success", res?.data?.message ?? "Data Fetch Successfully");
          } else {
            setClarificationRemark("");
            setShowData(false);
            setShowReopenDivison(false)
            setShowRemarks(false)
            setStatusCode(null)

            toaster("warning", res?.data?.message ?? "Something Went Wrong!");
          }
        })
        .catch((error) => {
          toaster("warning", error?.data?.message ?? "Something Went Wrong");
          setShowData(false);
          setStatusCode(null)
          setShowRemarks(false)
        });
    } else {
      toaster("warning", "Sugg ID entered contains Alpha numerical value");
      setShowData(false);
      setStatusCode(null)
      setShowRemarks(false)
    }
  };

  // ---------------------Get Agency Details Based of Division ID------------ //
  const getAgency = (divisionId) => {
    const params = { division: divisionId };
    getSuggAgency(params)
      .then((res) => {
        if (res?.status === 200) {
          const agency_list = res?.data?.data?.agency_list.map((agency) => ({
            id: agency?.agency_code,
            label: agency?.agency_name,
          }));
          setAgencyData(agency_list);
        } else {
          setAgencyData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      });
  };

  //--------------Get Suggestion Office API-------------------------
  const getSuggestionOfficeAgencyDashBoardList = () => {
    setLoading(true);
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      pa: AdminLoginData?.userData?.PersArea,
    };
    getSuggestonOfficeAgencyDashboard(params)
      .then((res) => {
        const { status, data } = res;
        if (status === 200 || status === 201) {
          setDivisionList(data?.data ? [...data.data] : []);
        } else {
          toaster("warning", res?.data?.message ?? "Something Went Wrong!");
        }
      })
      .catch((error) => {
        toaster("warning", error?.data?.message ?? "Something Went Wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleSlected = (data) => {
    setTotalPages(1);
    setCurrentPage(1);
    setSearchText('');
    const searchItem = '';
    setShowAddbtn(true);
    if (data.key != suggestionMasterScreen.ReOpen) {
      setAddFormBtn(true);
      // getSpecialSchemeListDetails();
      setShowMasterScreenDashboard(true);
      setSearchFunction("");
      setShowData(false)
      setStatusCode(null)
      setShowReopenAgency(false)
      setShowReopenDivison(false)
      setShowRemarks(false)
      setSelectedReopenStatus("")
      setClarificationRemark("");
      setDivisionCode(null);
      setShowPagination(true);
      setShowMasterScreenDashboardReopen(false);
    } else if (data.key == suggestionMasterScreen.ReOpen) {
      getSuggestionOfficeAgencyDashBoardList();
      setAddFormBtn(false);
      setShowMasterScreenDashboard(false);
      setShowMasterScreenDashboardReopen(true);
      setShowPagination(false);
    }

    if (data.key == suggestionMasterScreen.SchemeList) {
      getSpecialSchemeListDetails();
    }

    if (data.key == suggestionMasterScreen.DivisionList) {
      getMasterDivisionList(1,searchItem);
    }
    if (data.key == suggestionMasterScreen.AgencyList) {
      getMasterAgencyList(1,searchItem);
    }
    if(data.key == suggestionMasterScreen.Evaluator){
      getMasterEvaluator(1,searchItem);
    }
    if(data.key == suggestionMasterScreen.DivisionCordinator){
      getMasterDivisionalCordinator(1,searchItem);
    }
    if(data.key == suggestionMasterScreen.CostCenter){
      getMasterCostCenterList(1,searchItem);
    }
    setSelectedScreen(data);
  };

  const handleSearchSlected = (data) => {
    setTotalPages(1);
    setCurrentPage(1);
    // setShowAddbtn(true);
    if (data.key == suggestionMasterScreen.SchemeList) {
      getSpecialSchemeListDetails();
    }
    if (data.key == suggestionMasterScreen.DivisionList) {
      getMasterDivisionList(1);
    }
    if (data.key == suggestionMasterScreen.AgencyList) {
      getMasterAgencyList(1);
    }
    if(data.key == suggestionMasterScreen.Evaluator){
      getMasterEvaluator(1);
    }
    if(data.key == suggestionMasterScreen.DivisionCordinator){
      getMasterDivisionalCordinator(1);
    }
    if(data.key == suggestionMasterScreen.CostCenter){
      getMasterCostCenterList(1);
    }
    // setSelectedScreen(data);
  };

  const getSpecialSchemeListDetails = async () => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await getSuggestionSpecialScheme(param)
      .then((res) => {
        if (res.status === 200) {
          setMasterScreenListData(res?.data?.data);
          // setShowAddbtn(true);
        } else {
          setMasterScreenListData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Agency Master
  const getMasterAgencyList = async (page_number,checkSearch) => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
      search_text: checkSearch == '' ? '' : searchText?.titleText ?? '',
      page_number: page_number ?? ''
    };
    const endpoint = param.comp_code+'/'+param.pa+'?page_number='+page_number+'&search_text='+param.search_text;
    await getAgencyAdminMasterScreen(endpoint,param)
      .then((res) => {
        if (res.status === 200) {
          const { paginated_data, total_pages } = res.data.data;
          setMasterScreenListData(paginated_data);
          setTotalPages(total_pages);
          if(paginated_data?.length == 0){
            // setShowAddbtn(true);
          }
        } else {
          setMasterScreenListData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Divisional Master
  const getMasterDivisionalCordinator = async (page_number,checkSearch) => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
      search_text: checkSearch == '' ? '' : searchText?.titleText ?? '',
      page_number: page_number ?? ''
    };
    await getDivsionalCordinator(param)
      .then((res) => {
        if (res.status === 200) {
          const { paginated_data, total_pages } = res.data.data;
          setMasterScreenListData(paginated_data);
          setTotalPages(total_pages);
          if(paginated_data?.length == 0){
            // setShowAddbtn(true);
          }
        } else {
          setMasterScreenListData([]);
          // setShowAddbtn(false);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Evaluator Master
  const getMasterEvaluator = async (page_number,checkSearch) => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
      search_text: checkSearch == '' ? '' : searchText?.titleText ?? '',
      page_number: page_number ?? ''
    };
    // const endpoint = param.comp_code+'/'+param.pa+'?page_number='+page_number;
    await getEvaluator(param)
      .then((res) => {
        if (res.status === 200) {
          const { paginated_data, total_pages } = res.data.data;
          setMasterScreenListData(paginated_data);
          setTotalPages(total_pages);
          if(paginated_data?.length == 0){
            // setShowAddbtn(true);
          }
        } else {
          setMasterScreenListData([]);
          // setShowAddbtn(false);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Division Master
  const getMasterDivisionList = async (page_number,checkSearch) => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
      search_text: checkSearch == '' ? '' : searchText?.titleText ?? '',
      page_number: page_number ?? ''
    };
    const endpoint = param.comp_code+'/'+param.pa+'?page_number='+page_number+'&search_text='+param.search_text;
    await getDivisionAdminMasterScreen(endpoint,param)
      .then((res) => {
        if (res.status === 200) {
          const { paginated_data, total_pages } = res.data.data;
          setMasterScreenListData(paginated_data);
          setTotalPages(total_pages);
          if(paginated_data?.length == 0){
            setShowAddbtn(true);
          }
        } else {
          setMasterScreenListData([]);
          // setShowAddbtn(false);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

   //Cost Center Master
   const getMasterCostCenterList = async (page_number,checkSearch) => {
    const searchItem = checkSearch == '' ? '' : searchText?.titleText ?? '';
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
      search_text: checkSearch == '' ? '' : searchText?.titleText ?? '',
      page_number: page_number ?? ''
    };
    const endpoint = param.comp_code+'/'+param.pa+'?page_number='+page_number+'&search_text='+param.search_text;
    await getCostCenterAdminMasterScreen(endpoint,param)
      .then((res) => {
        if (res.status === 200) {
          const { paginated_data, total_pages } = res.data.data;
          if(paginated_data?.length == 0){
            setShowAddbtn(true);
          }
          setMasterScreenListData(paginated_data);
          setTotalPages(total_pages);
        } else {
          setMasterScreenListData([]);
          // setShowAddbtn(false);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
 

  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      if (selectedScreen.key == suggestionMasterScreen.AgencyList) {
        getMasterAgencyList(currentPage - 1);
      }
      if (selectedScreen.key == suggestionMasterScreen.DivisionCordinator) {
        getMasterDivisionalCordinator(currentPage - 1);
      }
      if (selectedScreen.key == suggestionMasterScreen.Evaluator) {
        getMasterEvaluator(currentPage - 1);
      }
      if (selectedScreen.key == suggestionMasterScreen.DivisionList) {
        getMasterDivisionList(currentPage - 1);
      }
      if (selectedScreen.key == suggestionMasterScreen.CostCenter) {
        getMasterCostCenterList(currentPage - 1);
      }
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      if (selectedScreen.key == suggestionMasterScreen.AgencyList) {
        getMasterAgencyList(currentPage + 1);
      }
      if (selectedScreen.key == suggestionMasterScreen.DivisionCordinator) {
        getMasterDivisionalCordinator(currentPage + 1);
      }
      if (selectedScreen.key == suggestionMasterScreen.Evaluator) {
        getMasterEvaluator(currentPage + 1);
      }
      if (selectedScreen.key == suggestionMasterScreen.DivisionList) {
        getMasterDivisionList(currentPage + 1);
      }
      if (selectedScreen.key == suggestionMasterScreen.CostCenter) {
        getMasterCostCenterList(currentPage + 1);
      }
    }
  };

  const onAction = (listData, type) => {
    if (type === "update") {
      setIsFormUpdatePopup(true);
      setUpdateFormData(listData);
    } else if (type === "delete") {
      deleteForm(listData);
    } else if (type === "view") {
      setIsFormViewPopup(true);
      setUpdateFormData(listData);
    }
  };

  const deleteForm = (val) =>{
    if (selectedScreen.key == suggestionMasterScreen.SchemeList) {
      deleteSuggestionSchemeForm(val);
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionList) {
      deleteMasterDivision(val?.division_code);
    }
    if (selectedScreen.key == suggestionMasterScreen.AgencyList) {
      deleteMasterAgency(val?.agency_code);
    }
    if(selectedScreen.key == suggestionMasterScreen.Evaluator){
      deleteMasterEvaluator(val);
    }
    if(selectedScreen.key == suggestionMasterScreen.DivisionCordinator){
      deleteMasterDivsionalCordinator(val);
    }
    if(selectedScreen.key == suggestionMasterScreen.CostCenter){
      deleteMasterCostCenter(val.id);
    }

  }

  const addForm = () => {
    setAddFormData(true);
  };

  const deleteSuggestionSchemeForm = async (data) => {
    setLoading(true);
    let body = {
      scheme_name: data?.scheme_name,
      start_date: data?.start_date,
      end_date: data?.end_date,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await deleteSuggestionSchemeName(body, data.id)
    .then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        toaster("success", res?.data?.message ? res?.data?.message : "");
          getSpecialSchemeListDetails();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
        }
      })
      .catch((error) => {
        error?.status != 404 &&
        toaster("warning", error?.data?.message ? error?.data?.message : "");
      })
      .finally(() => {
        setLoading(false);
      });
  };
    
  const searchFilterFunction = (e) => {
    const searchValue = e.target.value;
    setSearchFunction(searchValue);
  };
    
  const suggestionReopen = () => {
      let req_body={}
      const sug_id = suggestionData?.suggestion_details?.id
      if(selectedReopenStatus === null && (statusCode == 4 || statusCode == 5)){
          toaster("warning", "Please select whom to send");
      }
      else{
        if(statusCode != 4 || statusCode != 5){

          if(statusCode == 7 || statusCode == 8 || statusCode == 9){
             if(selectedDivisionType){
                if(search === "" || !divisionCode || !agency || clarificationRemark === ""){
                  toaster("warning", "All fields are Required");
                  return
                }else{
                req_body = {
                  comp_code: AdminLoginData?.company?.company_code,
                  pa: AdminLoginData?.userData?.PersArea,
                  action: "Re-Open",
                  comment: clarificationRemark,
                  agency: agency ? agency?.id : null,
                  agency_name: agency ? agency?.label : null
                }
              }
            
            }
            else{
              req_body = {
                comp_code: AdminLoginData?.company?.company_code,
                pa: AdminLoginData?.userData?.PersArea,
                action: "Re-Open",
                comment: clarificationRemark,
                agency: agency ? agency?.id : null,
                agency_name: agency ? agency?.label : null
              }
            }
          }
          else{
            req_body = {
              comp_code: AdminLoginData?.company?.company_code,
              pa: AdminLoginData?.userData?.PersArea,
              action: "Re-Open",
              comment: clarificationRemark,
              agency: agency ? agency?.id : null,
              agency_name: agency ? agency?.label : null
            }
          }
        }
        if(selectedReopenStatus?.id == 0){
          if(search === "" || !divisionCode || !agency || clarificationRemark === ""){
            toaster("warning", "All fields are Required");
            return
          }else{
            req_body = {
              comp_code: AdminLoginData?.company?.company_code,
              pa: AdminLoginData?.userData?.PersArea,
              action: "Re-Open",
              comment: clarificationRemark,
              agency: agency?.id,
              agency_name: agency?.label,
              send_to_status:selectedReopenStatus?.id,
            };

          }
        }
        if(selectedReopenStatus?.id == 1){
          if(search === "" || clarificationRemark === ""){
            toaster("warning", "All fields are Required");
            return
          }else{
            req_body = {
              comp_code: AdminLoginData?.company?.company_code,
              pa: AdminLoginData?.userData?.PersArea,
              action: "Re-Open",
              comment: clarificationRemark,
              agency: null,
              agency_name: null,
              send_to_status:selectedReopenStatus?.id,
            };
          }
        }
        if(selectedReopenStatus?.id == 2){
          
          if(search === "" || clarificationRemark === ""){
            toaster("warning", "All fields are Required");
            return
          }else{
              req_body = {
                comp_code: AdminLoginData?.company?.company_code,
                pa: AdminLoginData?.userData?.PersArea,
                action: "Re-Open",
                comment: clarificationRemark,
                agency: orginalAgencyDetails?.id,
                agency_name: orginalAgencyDetails?.label,
                send_to_status:selectedReopenStatus?.id,
              };
          }
        }
        setLoading(true);
          submitReopenSuggestion(search, req_body)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                setClarificationRemark("");
                setShowData(false)
                setStatusCode(null)
                setShowReopenAgency(false)
                setShowReopenDivison(false)
                setShowRemarks(false)
                setSelectedReopenStatus("")
                getSuggestionByIdData(sug_id)
                toaster("success", res?.data?.message);
                
              } else {
                toaster("warning", res?.data?.message);
              }
            })
            .catch((error) => {
              toaster("warning", error?.data?.message);
            })
            .finally(() => {
              setLoading(false);
            });
        }
  };

  const handleDivision = (data) => {
    setSelectedDivisionType(data?.division_name);
    setDivisionCode(data?.division_code);
    setSuggestionId(suggestionId);
    setAgency(null);
    getAgency(data?.division_code);
    setShowReopenAgency(true)
  };

  const handleAgency = (data) => {
    setAgency(data);
  };

  const deleteMasterAgency = async (id) => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    
    await deleteAgencyAdminMaster(id,param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success( res?.data?.message ?? 'Agency deleted successfully', {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          });
        }else {
          res?.status != 404 &&
          toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
        }
        getMasterAgencyList(currentPage);
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteMasterDivsionalCordinator = async (val) => {
    setLoading(true);
    const param = {
      emp_id: val?.emp_id,
      division_code: val?.division_id,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await deleteDivsionalCordinator(param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success( res?.data?.message ?? 'Divisional cordinator deleted successfully', {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          });
        }else {
          res?.status != 404 &&
          toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
        }
        getMasterDivisionalCordinator(currentPage);
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteMasterEvaluator = async (val) => {
    setLoading(true);
    const param = {
      emp_id: val?.emp_id,
      agency_code: val?.agency_id,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await deleteEvaluator(param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success( res?.data?.message ?? 'Evaluator deleted successfully', {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          });
        }else {
          res?.status != 404 &&
          toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
        }
        getMasterEvaluator(currentPage);
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteMasterDivision = async (id) => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    
    await deleteDivisionAdminMaster(id,param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success( res?.data?.message ?? 'Agency deleted successfully', {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          });
        }else {
          res?.status != 404 &&
          toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
        }
        getMasterDivisionList(currentPage);
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteMasterCostCenter = async (id) => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await deleteCostCenterAdminMaster(id,param)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success( res?.data?.message ?? 'Agency deleted successfully', {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          });
        }else {
          res?.status != 404 &&
          toaster("warning", res?.data?.message ? res?.data?.message : "Something went wrong!");
        }
        getMasterCostCenterList(currentPage);
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dismisUpdateForm = () => {
    if (selectedScreen.key == suggestionMasterScreen.SchemeList) {
      getSpecialSchemeListDetails();
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionList) {
      getMasterDivisionList(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.AgencyList) {
      getMasterAgencyList(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionCordinator) {
      getMasterDivisionalCordinator(currentPage);
    }
    if(selectedScreen.key == suggestionMasterScreen.Evaluator){
      getMasterEvaluator(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.CostCenter) {
      getMasterCostCenterList(currentPage);
    }
    setIsFormUpdatePopup(false);
  };

  const closeUpdatePopUp = () => {
    if (selectedScreen.key == suggestionMasterScreen.SchemeList) {
      getSpecialSchemeListDetails();
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionList) {
      getMasterDivisionList(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.AgencyList) {
      getMasterAgencyList(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionCordinator) {
      getMasterDivisionalCordinator(currentPage);
    }
    if(selectedScreen.key == suggestionMasterScreen.Evaluator){
      getMasterEvaluator(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.CostCenter) {
      getMasterCostCenterList(currentPage);
    }
    setIsFormUpdatePopup(false);
  };

  const dismisAddForm = () => {
    if (selectedScreen.key == suggestionMasterScreen.SchemeList) {
      getSpecialSchemeListDetails();
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionList) {
      getMasterDivisionList(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.AgencyList) {
      getMasterAgencyList(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionCordinator) {
      getMasterDivisionalCordinator(currentPage);
    }
    if(selectedScreen.key == suggestionMasterScreen.Evaluator){
      getMasterEvaluator(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.CostCenter) {
      getMasterCostCenterList(currentPage);
    }
    setAddFormData(false);
  };

  const closePopup = () => {
    setShowData(false);
  };

  const closeAddPopUp = () => {
    if (selectedScreen.key == suggestionMasterScreen.SchemeList) {
      getSpecialSchemeListDetails();
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionList) {
      getMasterDivisionList(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.AgencyList) {
      getMasterAgencyList(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.DivisionCordinator) {
      getMasterDivisionalCordinator(currentPage);
    }
    if(selectedScreen.key == suggestionMasterScreen.Evaluator){
      getMasterEvaluator(currentPage);
    }
    if (selectedScreen.key == suggestionMasterScreen.CostCenter) {
      getMasterCostCenterList(currentPage);
    }
    setAddFormData(false);
  };

  const handleSendToReopen = (val) =>{
      setShowReopenDivison(false);
      setShowReopenAgency(false);
      setSelectedDivisionType(null);
      setAgency(null);

      if(val.id == 0){
        setShowReopenDivison(true);
      }
      if(val.id == 1){
        setShowReopenDivison(false);
      }
      setSelectedReopenStatus(val);    
  }

  useEffect(()=>{
    if(!searchText){
      return;
    }
    const handler = setTimeout(()=>{
      handleSearchSlected(selectedScreen);
    },500)

    return ()=>{
      clearTimeout(handler)
    };
  },[searchText])

  return (
    <div>
      <LoaderIndicator loading={loading} />
      <div className="px-0 pt-0 h-100">
        <div className="justify-content-between align-item-center mt-1">
          <div className="route-common-heading ml-3">{selectedScreen? (selectedScreen.value).toUpperCase() : ''} MASTER SCREEN</div>
          <div className="justify-content-end align-item-center">
            {
              showMasterScreenDashboard ? (
                <div className='row' style={{position: 'relative'}}>
                  <div className='p-placeholder-text mb-1'>
                      <input
                          className="form-control p-placeholder px-2 p-placeholder-admin"
                          type="text"
                          placeholder='Search'
                          onChange={(e) => setSearchText({ titleText: e.target.value })}
                          value={searchText?.titleText ?? ''}
                      />
                  </div>
                  <div type='button' className='serchIconSize-gems'  style={{position: 'absolute',right:'6px'}} onClick={() => handleSearchSlected(selectedScreen)}>
                      <img alt="radioOn" className='filterIcon' src={searchIcon} />
                  </div>
                </div>
              ) : null}
           
            <div className="ml-2 dropdown-Text">
              <Dropdown
                data={masterScreenList}
                selectedValue={(data) => {
                  handleSlected(data);
                }}
                value={selectedScreen?.value ?? "Select Master Screen Type"}
                render={"value"}
                arrow={"true"}
                height={"dependOnContent"}
              />
            </div>

            {addFormBtn ? (
              <div>
                {/* <input
                  type="button"
                  value="ADD FORM"
                  className={`${
                    selectedScreen ? "validateBtn" : "validateBtnDisabled"
                  }`}
                  onClick={() => addForm()}
                  disabled={!selectedScreen}
                /> */}
                <input
                  type="button"
                  value="Master Add"
                  className={`${
                    showAddbtn ? "validateBtn" : "validateBtnDisabled"
                  }`}
                  onClick={() => addForm()}
                  // disabled={!selectedScreen}
                  disabled = {!showAddbtn}
                />
              </div>
            ) : null}

            {/* <div>
              <input
                type="button"
                value="Master Add"
                className={`${
                  showAddbtn ? "validateBtn" : "validateBtnDisabled"
                }`}
                onClick={() => addForm()}
                // disabled={!selectedScreen}
                disabled = {!showAddbtn}
              />
            </div> */}

          </div>
        </div>

        <div className="row mt-2">
          {
            selectedScreen ? (
              <>
                {showMasterScreenDashboard ? (
                  <>
                    <div className="profile-inner-card w-100">
                      <MasterScreenDashboard
                        tabName={selectedScreen}
                        masterScreenListData={masterScreenListData}
                        onAction={onAction}
                      />
                    </div>
                  </>
                ) : null}
        
                {showMasterScreenDashboardReopen ? (
                  <div
                    style={{ position: "relative" , paddingBottom: "2.6rem" }}
                    className="align-items-center w-100 gap-10px filter-p5"
                  >
                    <div className="d-flex flex-wrap align-items-center mx-2">
                      <div className="flex-direction-column ml-3">
                        <div className="p-sub-heading ml-2 required">
                          Suggestion ID
                        </div>
                        <div style={{position: "relative"}} className="d-flex align-items-center">
                          <input
                            className="inpt input-date w-100 w-md-auto"
                            type="text"
                            placeholder="Sugg No..."
                            onChange={(e) => searchFilterFunction(e)}
                            value={search}
                          />
                          <div
                            type="button"
                            className="serchIconSize-master-screen"
                            onClick={() => getSuggestionById(search)}
                          >
                            <img
                              alt="radioOn"
                              className="filterIcon"
                              src={searchIcon}
                            />
                          </div>
                        </div>
                      </div>
        
                      {(statusCode == 4 || statusCode == 5) && (
                        <>
                          <div className="flex-direction-column ml-3">
                            <div className="p-sub-heading ml-2 required">
                              {"Whom to send"}
                            </div>
                            <DynamicDropdown
                              data={sendToReopen}
                              render="title"
                              value={selectedReopenStatus?.title ? selectedReopenStatus?.title : "Select Value"}
                              selectedValue={(data) =>
                                handleSendToReopen(data)
                              }
                              arrow={"true"}
                              height={"dependOnContent"}
                              width={"w-auto"}
                              boxWidth="w-auto"
                              placeholder={"Select Value"}
                              
                            />
                          </div>
                        </>
                      )}
        
                      { 
                        showReopenDivison && (
                          <div className="flex-direction-column ml-3">
                            <div className={`p-sub-heading ml-2 ${statusCode == 7 || statusCode == 8 || statusCode == 9 ? '' : 'required'}`}>
                              {"Select Division"}
                            </div>
                            <DynamicDropdown
                              data={divisionList}
                              render="division_name"
                              value={selectedDivisionType}
                              selectedValue={(data) => handleDivision(data)}
                              arrow={"true"}
                              height={"dependOnContent"}
                              width={"w-auto"}
                              boxWidth="w-auto"
                              placeholder = {"Select Division"}
                            />
                          </div>
                        )
                      }
        
                      {
                        showReopenAgency && (
                          <div className="flex-direction-column ml-3">
                            <div className={`p-sub-heading ml-2 ${statusCode == 7 || statusCode == 8 || statusCode == 9 ? '' : 'required'}`}>
                              {"Select Agency"}
                            </div>
                            <DynamicDropdown
                              data={agencyData}
                              render="label"
                              value={agency?.label ? agency?.label : "Select Agency"}
                              selectedValue={(data) => handleAgency(data)}
                              arrow={"true"}
                              height={"dependOnContent"}
                              width={"w-auto"}
                              boxWidth="w-atuo"
                            />
                          </div>
                        )
                      } 
        
                      {showRemarks ? (<><div style={{position: "relative"}} className="flex-direction-column ml-3">
                            <div className="p-sub-heading ml-2 required">
                              {"Remarks"}
                            </div>
                            <div className="d-flex flex-column w-100">
                              <div className="row w-100 row-gap-10px">
                                <div className="flex-direction-column">
                                  <textarea style={{height: "36px"}}
                                    className="form-control required esep-placeholder-editable px-3"
                                    type="text"
                                    onChange={(e) => {
                                      setClarificationRemark(e.target.value);
                                    }}
                                    value={clarificationRemark}
                                    placeholder={"Remarks"}
                                    maxLength={1024}
                                  />
                                  <span className={`span-character-css p-sub-heading ml-2 ${clarificationRemark.length >= 924 ? 'text-danger' : ''}`}>{1024 - clarificationRemark.length} characters left</span>
                                </div>
                              </div>
                            </div>
                          </div>
        
                          <div className="flex-direction-column ml-3 mt-10px">
                              <input
                                type="button"
                                value="Submit"
                                className={` ${"validateBtn"}`}
                                onClick={() =>  suggestionReopen()}
                              />
                          </div></>) : null}
                    </div>
                  
                    {/* ) : (
                      <div className="grid-center">
                        <DataNotFound />
                      </div>
                    ) : null} */}
        
                  </div>
                ) : null }
              </>
            ) : (
              <div className="grid-center">
                <DataNotFound />
              </div>
            )
          }

          {showData ? (
            <>
              <SuggestionReOpenDetails
                title={"Suggestion Details"}
                backAction={closePopup}
                selectedRecordId={suggestionData?.suggestion_details?.id}
                showComment={false}
                showAwardType={false}
                selectedStatus ={selectedStatus}
              />
            </>
          ) : null}

          { showPagination && masterScreenListData?.length > 0 &&  (
            <div className="col-md-12 w-100 d-flex justify-content-center" style={{marginTop:'-20px'}}>
              <Pagination>
                {currentPage != 1 && (
                  <>
                    <Pagination.Prev
                      onClick={() => {
                        handlePageCount("decrement");
                      }}
                    />
                    <Pagination.Item disabled onClick={() => setCurrentPage(1)}>
                      {1}
                    </Pagination.Item>
                    <Pagination.Ellipsis disabled />
                  </>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage != totalPages && (
                  <>
                    <Pagination.Ellipsis disabled />
                    <Pagination.Item
                      disabled
                      onClick={() => setCurrentPage(totalPages)}
                    >
                      {totalPages}
                    </Pagination.Item>
                    <Pagination.Next
                      onClick={() => {
                        handlePageCount("increment");
                      }}
                    />
                  </>
                )}
              </Pagination>
            </div>
          )}

          {isFormUpdatePopup && (
            <UpdatePopUpScreen
              updateFormData={updateFormData}
              filteredValue={selectedScreen}
              dismisUpdateForm={dismisUpdateForm}
              closeUpdatePopUp={closeUpdatePopUp}
            />
          )}

          {AddFormData && (
            <AddPopUpScreen
              dismisAddForm={dismisAddForm}
              closeAddPopUp={closeAddPopUp}
              tabName = {selectedScreen}
            />
          )}

          {isFormViewPopup && (
            <div className="modal popup-box">
              <div className={"popupCard-box p-0 w-50"}>
                <div className="modal-header">
                  <h4 className="modal_title black">
                    {"Notice Board Attachment"}
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setIsFormViewPopup(false);
                    }}
                  >
                    &times;
                  </button>
                </div>
                {updateFormData.image_path ? (
                  <div className="modal-body m-15px scroll-auto">
                    <span className="flex-direction-column">
                      <span className="d-flex flex-direction-column px-10px pb-10px">
                        <>
                          <img
                            src={
                              updateFormData?.image_path
                                ? updateFormData?.image_path
                                : ""
                            }
                            alt=""
                            className="upload_bills_modal_body"
                          />
                        </>
                      </span>
                    </span>
                  </div>
                ) : (
                  <div>
                    <DataNotFound />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
 )};

export default SuggestionMasterScreen;
