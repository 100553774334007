export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const MANAGER_LOGIN_SUCCESS = 'MANAGER_LOGIN_SUCCESS';
export const FIELD_LEVEL_EMP_SUCCESS = 'FIELD_LEVEL_EMP_SUCCESS';
export const TOGGLE_LOGIN_STATUS = 'TOGGLE_LOGIN_STATUS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_SERVICE_TOKEN = 'GET_SERVICE_TOKEN';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_SERVICE_ENABLE_DISABLE = 'SET_SERVICE_ENABLE_DISABLE';

export const SET_ADMIN_COMPANY ='SET_ADMIN_COMPANY';
export const GET_ADMIN_TOKEN ='GET_ADMIN_TOKEN'
export const LOGOUT = 'LOGOUT';
export const APP_LOGOUT = 'APP_LOGOUT';
export const EMPTY_LOGIN_STATE='EMPTY_LOGIN_STATE'
export const EMP_MODULES = 'EMP_MODULES'
//profile
export const GET_EMPLOYEE_PROFILE_DETAILS ='GET_EMPLOYEE_PROFILE_DETAILS'

//Learning architecture
export const GET_UJR_DATA = 'GET_UJR_DATA'

//Overtime Booking
export const GET_OT_DATA = 'GET_OT_DATA'

//Eseparation Status
export const GET_ESEP_STATUS = 'GET_ESEP_STATUS'

//for Admin scheduleer jobs 
export const SET_SCHEDULE_JOBS ='SET_SCHEDULE_JOBS';

// service unavailable action performed on Homeaction and active popup
export const GET_AVAILABLE_SERVICE_DATA = 'GET_AVAILABLE_SERVICE_DATA';
export const AVAILABLE_SERVICE_DATA_LOGOUT = 'AVAILABLE_SERVICE_DATA_LOGOUT';
export const GET_ACTIVE_POPUP = 'GET_ACTIVE_POPUP';
export const GET_ACTIVE_POPUP_COUNT = 'GET_ACTIVE_POPUP_COUNT';

//leave
export const GET_LEAVE_REQUEST_TOTAL_DAYS = 'GET_LEAVE_REQUEST_TOTAL_DAYS'
export const CLEAR_DATA = 'CLEAR_DATA'

// previous leaves
export const PREV_LEAVE_ACCESS = 'PREV_LEAVE_ACCESS'

//admin
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const TOGGLE_ADMIN ='TOGGLE_ADMIN';
export const ADMIN_DATA = 'ADMIN_TYPE'
export const ADMIN_ROLE = 'ADMIN_ROLE'
export const ADMIN_OPERATIVE_ROLE = 'ADMIN_OPERATIVE_ROLE'
export const ADMIN_EMP_ID = 'ADMIN_EMP_ID'
export const ADMIN_USER_LOGIN_SUCCESS = 'ADMIN_USER_LOGIN_SUCCESS';
export const ADMIN_GET_EMPLOYEE_PROFILE_DETAILS = 'ADMIN_GET_EMPLOYEE_PROFILE_DETAILS'

//exit form
export const EXIT_FORM_APPLICABLE ='EXIT_FORM_APPLICABLE'
export const EXIT_FORM_DATA = 'EXIT_FORM_DATA'

//transfer module
export const BHR_APPLICABLE = 'BHR_APPLICABLE'

//digital signing module
export const IDT_APPLICABLE ='IDT_APPLICABLE'

//COI module

export const GET_COI_TYPE='GET_COI_TYPE'
export const CLEAR_COI_TYPE_DATA='CLEAR_COI_TYPE_DATA'
export const GET_COI_TYPE_A1_DATA='GET_COI_TYPE_A1_DATA'
export const GET_COI_TYPE_A2_DATA='GET_COI_TYPE_A2_DATA'
export const GET_COI_TYPE_A3_DATA='GET_COI_TYPE_A3_DATA'

//annountment
export const GET_ANNOUNCEMENT_DATA ='GET_ANNOUNCEMENT_DATA'

//profile Documents
export const GET_PAN_DETAILS = 'GET_PAN_DETAILS';
export const GET_AADHAR_DETAILS = 'GET_AADHAR_DETAILS';
export const GET_PERMANENT_ADDRESS_DETAILS = 'GET_PERMANENT_ADDRESS_DETAILS';
export const GET_CURRENT_ADDRESS_DETAILS = 'GET_CURRENT_ADDRESS_DETAILS';
export const GET_CONTACT_DETAILS = 'GET_CONTACT_DETAILS';
export const GET_BANK_DETAILS = 'GET_BANK_DETAILS';

//CHOICE PAY
export const EMPLOYEE_APPLICABLE_FOR_CHOICE_PAY = 'EMPLOYEE_APPLICABLE_FOR_CHOICE_PAY'

//CFM MODULES 
export const GET_SUCCESS_FACTOR_USER_DETAILS = 'GET_SUCCESS_FACTOR_USER_DETAILS';
export const GET_DIRECT_REPORTEE_DETAILS = 'GET_DIRECT_REPORTEE_DETAILS';

// Admin Blucollar registration
export const GET_TAB = 'GET_TAB'

//Suggestion Scheme
export const SUGG_APPLICABLE = 'SUGG_APPLICABLE'

//COI LOGIN POPUP
export const COI_POPUP_LOGIN = 'COI_POPUP_LOGIN'
export const SET_APPLICABLE_SUGGESTION = 'SET_APPLICABLE_SUGGESTION';