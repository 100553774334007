import * as type from '../../../../redux/action/ActionType';

export const authUser = (res) => {
    return {
        type: type.USER_LOGIN_SUCCESS,
        userData: res,
        payload: res
    }
}

export const authManager = (res) => {
    return {
        type: type.MANAGER_LOGIN_SUCCESS,
        managerData: res,
        payload: res
    }
}
export const getProfileImage = (res) => {
    return {
        type: type.GET_EMPLOYEE_PROFILE_DETAILS,
        payload: res
    }
}
export const getUjrData = (res) => {
    return {
        type: type.GET_UJR_DATA,
        payload: res
    }
}
export const getOTData = (res) => {
    return {
        type: type.GET_OT_DATA,
        payload: res
    }
}
export const esepStatusData = (res) => {
    return {
        type: type.GET_ESEP_STATUS,
        payload: res
    }
}
export const setCompany = (res) => {
    return {
        type: type.SET_COMPANY,
        payload: res
    }
}

export const setServiceEnableDisable = (res) => {
    return {
        type: type.SET_SERVICE_ENABLE_DISABLE,
        payload: res
    }
}

export const isApplicableForExitForm = (res) => {
    return {
        type: type.EXIT_FORM_APPLICABLE,
        payload: res
    }
}

//------cfm-------------------------------
export const userSFMDetails = (res) => {
    return {
        type: type.GET_SUCCESS_FACTOR_USER_DETAILS,
        payload: res
    }
}

//--------For Choice Pay module-----------
export const isApplicableForChoicePay = (res) => {
    return {
        type: type.EMPLOYEE_APPLICABLE_FOR_CHOICE_PAY,
        payload: res
    }
}

export const isFieldlevelEmployee = (res) => {
    return {
        type: type.FIELD_LEVEL_EMP_SUCCESS,
        isFieldLevelEmployee: res,
        payload: res
    }
}

export const isApplicableForBHR = (res) => {
    return {
        type: type.BHR_APPLICABLE,
        payload: res
    }
}
export const isApplicableForIDT = (res) => {
    return {
        type: type.IDT_APPLICABLE,
        payload: res
    }
}
export const esepEmployee = (res) => {
    return {
        type: type.EXIT_FORM_DATA,
        payload: res
    }
}

export const toggleLoginStatus = value => ({
    type: type.TOGGLE_LOGIN_STATUS,
    payload: value
})

export const setEmpModules = (value) => {
    return ({
        type: type.EMP_MODULES,
        payload: value
    })
}

export const emptyLoginState = value => ({
    type: type.EMPTY_LOGIN_STATE,
})

//--------For Suggestion scheme module-----------
export const isApplicableForSuggestionScheme = (res) => {
    return {
        type: type.SUGG_APPLICABLE,
        payload: res
    }
}

export const isCOIPopupLogin = (value) => ({
    type: type.COI_POPUP_LOGIN,
    payload : value
})