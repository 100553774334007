import React, { useState } from "react";
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ehackRouter, ehackRouterInstructions } from "../../../../constants/api.service";

function EHackHome() {
  const [loading, setLoading] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [instructionDetails, setInstructionDetails] = useState([]);
  const loginData = useSelector((state) => state.loginState);
  useEffect(() => {
    getEHackInstructions();
  }, []);
  const AllTabs = [{ name: "E Hack" }];

  const getEHackInstructions = async () => {
    try {
      setLoading(true);
      const res = await ehackRouterInstructions()
      if (res?.status == 200) {
        setInstructionDetails(res?.data?.data);
      } else {
        toast.warn(res?.data?.message, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
    } catch (error) {
      toast.warn(error.message || "An error occurred.", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastWarn",
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleLocalTabs = (index) => {
    setActiveTabIndex(index);
  };
  const eHackRoutingApi = async () => {
    try {
      setLoading(true);
      const params = {
        email: loginData?.userData?.Imailid,
        first_name: loginData?.userData?.Firstname,
        last_name: loginData?.userData?.LastName,
      };
      const res = await ehackRouter(params)
      if (res?.status == 200) {
        const url = res?.data?.data;
        window.open(url, "_blank");
      } else {
        toast.warn(res?.data?.message, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
    } catch (error) {
      toast.warn(error.message || "An error occurred.", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastWarn",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row pb-0 pt-12px px-12px h-100 ">
      <LoaderIndicator loading={loading} />
      <div className="col-md-12 justify-content-center align-item-center px-0">
        <div className="col-md-12 card-new h-100">
          <div className="row justify-content-between">
            <div className="row mt-3 ss">
              {AllTabs.map((tab, index) => (
                <div
                  style={{ padding: "10px 40px" }}
                  key={index}
                  className={
                    index === activeTabIndex ? "tab-active" : "tab-inactive"
                  }
                  onClick={() => toggleLocalTabs(index)}
                >
                  {tab.name}
                </div>
              ))}
            </div>
          </div>
          <div className="mt-3px">
            <hr />
          </div>
          <div className="w-100">
            <div>
              <div className="justify-content-between mt-3">
                <div className="card-heading mx-4 mt-1">Instructions</div>
              </div>
              <div>
                {instructionDetails &&
                  instructionDetails.map((data) => {
                    return (
                      <div>
                        <div className="row">
                          <h2 className="coi-sub-text mx-4 mr-4 mt-2">
                            {data.line}
                          </h2>
                        </div>
                      </div>
                    );
                  })}
                {instructionDetails && (
                  <div
                    className="d-flex justify-content-center align-item-center mt-4 mb-4"
                    onClick={eHackRoutingApi}
                  >
                    <input
                      type="button"
                      class="btn btn-primary common-btn declaration_button_text px-3"
                      value="PROCEED FOR E HACK"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EHackHome;
