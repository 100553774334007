import React from "react";
import { Route, Switch } from "react-router-dom"; // <-- New code
import { Redirect } from 'react-router-dom';
import PageNotFound from '../components/shared/notFound/PageNotFound';
import { configStore } from "../redux/ConfigStore";
import NewLoginScreen from '../components/ScreenType/employeeScreens/login/LoginScreen';
import AdminLogin from '../components/ScreenType/adminScreens/login/AdminLogin';
import RouteComponent from './RouteComponent';
import '../assets/styles/text.css';
import '../App.css';
import AppInfo from '../components/shared/appInfo/AppInfo';

export function RenderRoutes() {
    const isEmployeeLoggedIn = configStore.getState().loginState.isLoggedIn
    const isEmployeeOTAccess = configStore.getState().loginState.OTData?.is_supervisor == true || configStore.getState().loginState.OTData?.is_manager == true
    const isAdminLoggedIn = configStore.getState().adminLoginState ? configStore.getState().adminLoginState.isAdminLoggedIn : false
    const adminRole = configStore.getState().adminLoginState.adminRoles.length != 0 ? configStore.getState().adminLoginState.adminRoles : []
    // const isBHR = configStore.getState().loginState.isBHR
    const isExitFormApplicable = configStore.getState().loginState.isExitFormApplicable
    const screens = [
        { path: '/home', component: 'home', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/document', component: 'document', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/performance', component: 'cfm', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/exitform', component: 'exit', show: (isEmployeeLoggedIn && isExitFormApplicable), isAdmin: false },
        { path: '/esepRequest', component: 'esepRequest', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/esepDashboard', component: 'esepDashboard', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/esepRequestTracker', component: 'esepRequestTracker', show: isEmployeeLoggedIn, isAdmin: false },
        // { path: '/exitRequest', component: 'exitRequest', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/transfer', component: 'transfer', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/transferRequest', component: 'transferRequest', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/profile', component: 'profile', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/leave', component: 'leave', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/overTime', component: 'overTime', show: isEmployeeOTAccess, isAdmin: false },
        { path: '/coi', component: 'coi', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/attendance', component: 'attendance', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/announcement', component: 'announcement', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/todo', component: 'todo', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/approval', component: 'managerApproval', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/teams', component: 'managerTeams', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/directReportee', component: 'directReporteeDetail', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/momentsThatMatter', component: 'momentsThatMatter', show: isEmployeeLoggedIn, isAdmin: false, },
        { path: '/admin/home', component: 'AdminLanding', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/leave', component: 'adminLeave', show: isAdminLoggedIn, isAdmin: true },
        // { path: '/admin/notification', component: 'adminNotification', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/transfer', component: 'adminTransfer', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/transfer/admin', component: 'transferAdminRequest', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/todo', component: 'adminTodo', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/exit', component: 'adminExit', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/developerTools', component: 'devTools', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/screenEnableDisable', component: 'screenEnableDisable', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/coi', component: 'adminCoi', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/eseparationHome', component: 'adminEseparation', show: isAdminLoggedIn, isAdmin: true },
        { path: '/notification', component: 'notificationDetails', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/learninglandscape', component: 'learningArchitecture', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/admin/notification/home', component: 'adminNotificationScreen', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/registrationinfo', component: 'viewRegistrationInfo', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/announcementNotification', component: 'announcementNotification', show: isAdminLoggedIn, isAdmin: true, },
        { path: '/paySpace', component: 'paySpace', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/gems', component: 'GEMSHomeScreen', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/admin/gems', component: 'adminGEMSHomeScreen', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/localConveyance', component: 'adminLocalConveyance', show: isAdminLoggedIn, isAdmin: true },
        { path: '/suggestionScheme', component: 'employeeSuggestionScheme', show: isEmployeeLoggedIn, isAdmin: false },
        { path: '/admin/overtime', component: 'adminOvertime', show: isAdminLoggedIn, isAdmin: true },
        { path: '/admin/suggestionScheme', component: 'adminSuggestionScheme', show: isAdminLoggedIn, isAdmin: true },
        { path: '/ehack', component: 'eHackHome', show: isEmployeeLoggedIn, isAdmin: false }
        
    ]
    if (isAdminLoggedIn) {
        for (var i = 0; i < adminRole.length; i++) {
            console.log("adminRole. i : ", i);
            switch (adminRole[i]) {
                case 'clearance-team':
                    screens.push({ path: '/admin/clearance', component: 'adminClearance', show: isAdminLoggedIn, isAdmin: true })
            }
        }
    }
    return (
        <Switch>
            <Route exact path='/login' render={() =>
                <NewLoginScreen />
            } />
            <Route exact path='/appinfo' render={() =>
                <AppInfo />
            } />
            <Route exact path='/admin' render={() =>
            (<div>
                <AdminLogin />
            </div>)
            } />
            {screens.map((list, i) => (
                <Route key={i} exact path={list.path} render={(props) =>
                    <RouteComponent
                        ComponentToPassDown={list.component}
                        show={list.show}
                        location={props.location}
                    />
                } />
            ))
            }
            <Route exact strict path='/' render={() => <Redirect to='/login' />} />
            <Route exact path='/*' render={() => (<div><div className="contents"><PageNotFound /></div></div>)} />
        </Switch>
    );
}
