import React, { Component } from 'react';
import CommonButton from '../../../shared/button/GradientButton';
import moment from 'moment';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { attendanceLeaveType, exportToExcel } from '../../../../utils/Utils';
import { attendanceLwopDetails } from '../../../../constants/Messages';
import NodataApproval from '../../../../assets/drishti_images/nodatafound/todolist-nodata.svg';
import Dropdown from '../../../shared/components/Dropdown';

const confirmation = 'Confirmantion'
const TodaysDate = new Date();
const TodaysDates = TodaysDate.toISOString().slice(0, 10);
const validationNotes = `Only latest 3 months data can be generated in the report.`
class LwopReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            toDate: '',
            fromDate: '',
            reporteeAttendanceData: [],
            filterData: attendanceLeaveType(),
            statusCode: '',
            statusType: 'All',
            activeYear:''
        }
    }

    componentDidMount(){
        this.getActiveYear();
    }

    handleFromDateFunction = (e) => {
        this.setState({
            fromDate: e.target.value,
        })
    }

    handleToDateFunction = (e) => {
        this.setState({
            toDate: e.target.value,
        })
    }

    async getAttendanceEnquiryDetails(type) {
        const { action, reporteeEmpId, reporteeDoj } = this.props;
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            emp_id:this.props.loginData?.userData?.Perno,
            from_date: this.state.fromDate ? moment(this.state.fromDate).format("YYYY-MM-DD") : '',
            to_date: this.state.toDate ? moment(this.state.toDate).format("YYYY-MM-DD") : '',
            date_of_joining:this.props.loginData?.userData?.Doj ? moment(this.props.loginData?.userData?.Doj).format("YYYY-MM-DD") : '',
        }
        fetchApiCall(Config.generatelWOPAttendanceReports, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    if (type === 'search') {
                        this.setState({
                            loading: false,
                            reporteeAttendanceData: res.data.data ? res.data.data : [],
                            message: res.data.message
                        })
                    } else if (type === 'export') {
                        this.setState({ loading: false });
                        this.exportAllData(res?.data?.data ? res?.data?.data : [])
                        return
                    }
                } else {
                    this.setState({
                        loading: false,
                        reporteeAttendanceData: []
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    reporteeAttendanceData: []
                });
                toast.error('Something went wrong', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    exportAllData = (exportData) => {
        const { action, reporteeEmpId } = this.props;
        if (exportData) {
            let colNames = [attendanceLwopDetails.colNames]
            let attendanceRecords = exportData.map((data, idx) => {
                return {
                    SR_NO: idx + 1,
                    BEGDA: moment(data.BEGDA).format("DD-MM-YYYY"),
                    ENDDA: moment(data.ENDDA).format("DD-MM-YYYY"),
                    LEAVE_TYPE: data.LEAVE_TYPE,
                    TOT_LEAVES: data.TOT_LEAVES,
                    // LEAVE_NAME: data.LEAVE_NAME,
                    // REASON: data.REASON,
                    CURRENT_STATUS: data.CURRENT_STATUS
                }
            })
            exportToExcel(this.props.loginData?.userData?.Perno + '_' + "LWOP Leave Report", attendanceLwopDetails.ext, attendanceRecords, colNames)
            toast.success('LWOP Leave report downloaded successfully', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastSuccess'
            })
        }
    }

    getActiveYear() {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {}
        fetchApiCall(Config.getActiveYear, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        activeYear: res?.data?.data?.active_year ? res?.data?.data?.active_year : ''
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log("getActiveYear err : ", err)
            })
        }

    changeStatusDropdown = (data) => {
        this.setState({
            statusCode: data.code,
            statusType: data.value,
        })
    }

    render() {
        const { title, action } = this.props;
        const { reporteeAttendanceData, fromDate, toDate, loading } = this.state;
        const today = new Date();
        const priorDate = new Date(new Date().setMonth(today.getMonth() - 3));
        const toDay = priorDate.toISOString().substring(0, 10); // for hiding dates
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loading} />
                <div className={'declaration-box p-0 w-60'}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{title}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className={'pl-4 pr-4'}>
                            <div className='d-flex flex-direction-row'>
                                <div className='p-2'>
                                    <div className='p-sub-heading ml-3'>{'From Date'}</div>
                                    <div className='esep-placeholder-editable'>
                                        <input
                                            className="form-control esep-placeholder-editable px-3"
                                            type="date"
                                            // max={TodaysDates}
                                            // min={toDay}
                                            max={`${this.state.activeYear}-12-31`}
                                            min={`${this.state.activeYear}-01-01`}
                                            value={fromDate}
                                            onChange={(e) => this.handleFromDateFunction(e)}
                                        />
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <div className='p-sub-heading ml-3'>{'To Date'}</div>
                                    <div className='esep-placeholder-editable'>
                                        <input
                                            className="form-control esep-placeholder-editable px-3"
                                            type="date"
                                            disabled={fromDate === '' ? true : false}
                                            min={fromDate}
                                            // max={TodaysDates}
                                            max={`${this.state.activeYear}-12-31`}
                                            value={toDate}
                                            onChange={(e) => this.handleToDateFunction(e)}
                                        />
                                    </div>
                                </div>
                                {/* <div className='p-2 mt-1_5rem'>
                                    <Dropdown
                                        data={this.state.filterData}
                                        selectedValue={(data) => this.changeStatusDropdown(data)}
                                        value={this.state.statusType}
                                        render={'value'}
                                        arrow={'true'}
                                        height={'NotificationHeight'}
                                    />
                                </div> */}
                                <div className='mt-33px ml-2'>
                                    {fromDate === '' || toDate === '' ?
                                        <div>
                                            <CommonButton
                                                label="SEARCH"
                                                isDisableBtn={true}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <CommonButton
                                                label="SEARCH"
                                                isGradientBtn={true}
                                                onClick={() => this.getAttendanceEnquiryDetails('search')}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* <div className='d-flex flex-direction-row'>
                                <div className='purple-color performance-timeline-text-circle ml-3 mt-3'>{'Note:'}</div>
                                <div className='welcomomepopupdescription ml-1'>
                                    {validationNotes}
                                </div>
                            </div> */}
                            <div className='mt-2'> <hr /></div>
                            <div className='scroll-y height-41vh'>
                                {reporteeAttendanceData.length != 0 ?
                                    <table className="table table choice-pay-thead">
                                        <thead className=''>
                                            <tr>
                                                <th className='table-header' scope="col"><div className='thead pr-0'>{'Sr.No.'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'From Date'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'To Date'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'Duration'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead' >{'Total LWOP'}</div></th>
                                                {/* <th className='table-header' scope="col"><div className='thead' >{'Leave Name'}</div></th> */}
                                                {/* <th className='table-header' scope="col"><div className='thead'>{'Second Half'} </div></th> */}
                                                {/* <th className='table-header' scope="col"><div className='thead'>{'Reason'} </div></th> */}
                                                <th className='table-header' scope="col"><div className='thead'>{'Status'}</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reporteeAttendanceData.map((listValue, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td><div className="td table-data-font">{index + 1}</div></td>
                                                        <td><div className="td table-data-font" >{(listValue.BEGDA ? moment(listValue.BEGDA).format("DD-MM-YYYY") : '-')}</div></td>
                                                        <td><div className="td table-data-font" >{(listValue.ENDDA ? moment(listValue.ENDDA).format("DD-MM-YYYY") : '-')}</div></td>
                                                        <td><div className="td table-data-font">{(listValue.LEAVE_TYPE ? listValue.LEAVE_TYPE : '-')}</div></td>
                                                        <td> <div className="td table-data-font">{(listValue.TOT_LEAVES ? listValue.TOT_LEAVES : '-')}</div> </td>
                                                        {/* <td><div className="td table-data-font" >{(listValue.LEAVE_NAME ? listValue.LEAVE_NAME : '-')}</div></td> */}
                                                        {/* <td><div className="td table-data-font" >{(listValue.SH ? listValue.SH : '-')}</div></td> */}
                                                        {/* <td><div className="td table-data-font" >{(listValue.REASON ? listValue.REASON : '-')}</div></td> */}
                                                        <td><div className="td table-data-font">{(listValue.CURRENT_STATUS ? listValue.CURRENT_STATUS : '-')}</div></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                            <div>
                                                <img src={NodataApproval} alt="Nodata" style={{ "height": "6.25rem" }} />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>{'No Data Found'}</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer btnBox'>
                        {(action === 'Report') &&
                            <>
                                {fromDate === '' || toDate === '' ?
                                    <div className='d-flex justify-content-end p-2'>
                                        <CommonButton
                                            label="DOWNLOAD REPORT"
                                            isDisableBtn={true}
                                        />
                                    </div>
                                    :
                                    <>
                                        <CommonButton
                                            label="DOWNLOAD REPORT"
                                            isGradientBtn={true}
                                            onClick={() => this.getAttendanceEnquiryDetails('export')}
                                        />
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState
    }
}

export default connect(mapStatesToProps)(LwopReport);