import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import '../../../../assets/styles/popupStyles.css';
import backArrow from '../../../../assets/drishti_images/backArrow.svg';
import '../../employeeScreens/leave/LeaveScreen.css';
import radioOff from '../../../../assets/drishti_images/radioOff.png';
import radioOn from '../../../../assets/drishti_images/radioOn.png';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import { ta_shortlisting_radio_fiters } from '../../employeeScreens/gems/Constant'
import infoicon from '../../../../assets/drishti_images/infoicon.svg';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { toaster } from '../../../../utils/Utils';
import NewNotdata from '../../../shared/notFound/NewNoData';
import moment from 'moment';
import { getApplicants, downloadApplicantCV, updateStatus, fetchRejectList } from '../../employeeScreens/gems/Gems.api.service';
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';
import { warnings } from '../../employeeScreens/gems/Constant';
import Dropdown from '../../../shared/components/Dropdown';
const Note = 'Please select the appropriate reason for rejecting the application'

class ApplicantsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: 0,
            openShortPopup: false,
            appliacantList: [],
            appliacantListData: [],
            payRecoveryPopup: false,
            isViewStatus: false,
            popupHeader: '',
            tableViewKey: "Pending",
            radioFilters: ta_shortlisting_radio_fiters(),
            openRejectPopup: false,
            applicantListID: '',
            rejectKey: '',
            isRejectDisable: false,
            reasonList: [],
            newReasonList: [],
            reasonValue: 'Select'
        }
    }

    componentDidMount() {
        this.getProjectsList(this.props.selectedProjectsId, "Pending")
    }

    getProjectsList = (id, status) => {
        this.setState({ loading: true })
        const params = {
            status: status,
            emp_type: 'SPOC'
        }
        getApplicants(id, params, "admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    this.setState({
                        appliacantList: res.data.data ? res.data.data : [],
                    })
                }
                else {
                    toaster("", res.message ? res.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    projectDetails: [],
                    appliacantList: []
                })
                toaster("warning", "Something went wrong")
                console.log("get project catch error:", err)
            })
    }

    downloadCV = (applicantID) => {
        this.setState({
            loading: true
        })
        downloadApplicantCV(this.props.selectedProjectsId, applicantID, "admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                } else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("downloadApplicantCV catch err>>", err)
            })
    }
    approvalBySPOC = (applicantId, value) => {
        // console.log("SPOC APROVAL----->", value, applicantId)
        const status = value === "approval" ? "Shortlisted by SPOC" : value === "reject" ? "Rejected by SPOC" : ""
        const body = {
            "status": status,
            "rejection_reason": this.state.reasonValue ? this.state.reasonValue : ''
            // "emp_id": this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : ''
        }
        this.setState({
            appliacantListData: [],
            loading: true,
            openShortPopup: false,
            openRejectPopup: false
        })
        updateStatus(this.props.selectedProjectsId, applicantId, body, "admin")
            .then((res) => {
                this.setState({ loading: false })
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "")
                    this.setState({
                        checked: status === "Shortlisted by SPOC" ? 1 : status === "Rejected by SPOC" ? 2 : 0,
                        tableViewKey: status === "Shortlisted by SPOC" ? "Shortlisted by PG" : status === "Rejected by SPOC" ? "Rejected" : ""
                    })
                    this.getProjectsList(this.props.selectedProjectsId, status)
                    console.log("updateStatus res>>", res)
                }
                else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                console.log("updateStatus catch err>>", err)
            })
    }

    onPresentType = (key, type) => {
        if (type == "TA Screening") {
            this.setState({
                checked: key,
                listType: type,
                tableViewKey: "Pending"
            })
            this.getProjectsList(this.props.selectedProjectsId, "Pending")
        }
        else if (type == "PG Screening") {
            this.setState({
                checked: key,
                listType: type,
                tableViewKey: "Shortlisted by PG"
            })
            this.getProjectsList(this.props.selectedProjectsId, "Shortlisted by SPOC")
        }
        else if (type == "Rejected") {
            this.setState({
                checked: key,
                listType: type,
                tableViewKey: "Rejected"
            })
            this.getProjectsList(this.props.selectedProjectsId, "Rejected by SPOC")
        }
    }

    backToLandingScreen = () => {
        this.setState({

        }, this.props.closeApplicant())
    }

    onActionView = (data, index, type) => {
                this.setState({
            popupHeader: type,
            openShortPopup: true,
            appliacantListData: data
        },
            () => this.fetchReason()
        )
    }

    onDismiss = () => {
        this.setState({
            appliacantListData: [],
            openShortPopup: false,
            isRejectDisable: false,
            reasonValue: 'Select',
            openRejectPopup: false
        })
    }

    renderSFTooltip = props => (
        <div className='flex-direction-row tool-tip-font-gems'>
            <div className='flex-direction-column justify-content-around'>
                <span className='mr-4px'>{"Project Title :"}</span>
                <span className='mr-4px'>{"BHR Name :"}</span>
                <span className='mr-4px'>{"PG Name :"}</span>
                <span className='mr-4px'>{"Start Date :"}</span>
            </div>
            <div className='flex-direction-column justify-content-around'>
                <span>{this.props.hoverData?.title ? this.props.hoverData?.title : 'No Data'}</span>
                <span>{this.props.hoverData?.bhr?.CompName ? this.props.hoverData?.bhr?.CompName : 'No Data'}</span>
                <span>{this.props.hoverData?.project_guide?.CompName ? this.props.hoverData?.project_guide?.CompName : 'No Data'}</span>
                {this.props.hoverData?.actual_start_date ?
                    <span>{this.props.hoverData?.actual_start_date ? moment(this.props.hoverData?.actual_start_date).format("DD-MM-YYYY") : 'No Data'}</span> :
                    <span>{this.props.hoverData?.planned_start_date ? moment(this.props.hoverData?.planned_start_date).format("DD-MM-YYYY") : 'No Data'}</span>
                }
            </div>
        </div>
    );

    renderSFTooltipTable = props => (
        <div className='flex-direction-row tool-tip-font-gems-applicant'>
            <div className='flex-direction-column justify-content-around'>
                <span>{'Applicant already selected in another project.'}</span>
            </div>
        </div>
    );

    fetchReason = () => {
        this.setState({
            loading: true
        })
        fetchRejectList(this.state.reasonValue)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        reasonList: res.data.data ? res.data.data : [],
                    })
                    const tempRejectReasonList = this.state.reasonList.map(tempValue => ({ value: tempValue.reason, key: tempValue.id }))
                    this.setState({
                        newReasonList: tempRejectReasonList
                    })
                } else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("fetchRejectList catch err>>", err)
            })
    }

    changeResonsType = (data) => {
        this.setState({
            isRejectDisable: true,
            reasonValue: data.value,
        })
    }

    render() {
        const { loading, appliacantList, appliacantListData, popupHeader } = this.state;
                return (
            <div>
                <LoaderIndicator loading={loading} />
                <div className="main-contain" >
                    <div type='button' className='d-flex justify-content-start p-0'>
                        <img
                            alt="sendicon"
                            src={backArrow}
                            className="send-img mt-1rem"
                            onClick={() => this.backToLandingScreen()}
                        />
                        <div onClick={() => this.setState({ isSubmissionList: true })}
                            className='flex-direction-column flex-start mt-1rem'>
                            <div className='send-text'>
                                {/* {"Project heading"} */}
                                {this.props.hoverData?.title ? this.props.hoverData?.title : ''}
                            </div>
                        </div>
                        <div>
                            <OverlayTrigger placement="right" overlay={this.renderSFTooltip(this.props)}>
                                <img
                                    alt="infoicon"
                                    src={infoicon}
                                    className="admin-gems-info-icon mt-15px"
                                ></img>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className='d-flex row justify-content-between mt-3'>
                        <div className='d-flex row justify-content-start'>
                            <div className='status-dashboard ml-4 mt-2'>{'Status :'}</div>
                            {this.state.radioFilters.length != 0 && this.state.radioFilters.map((list, key) => {
                                return (
                                    <div key={key} className='mx-4 mt-1 mb-2 '>
                                        {this.state.checked === key ?
                                            <div onClick={() => this.onPresentType(key, list.title)}>
                                                <img alt="radioOn" className='radio' src={radioOn} />
                                                <label className="radioTxt" >{list.title}</label>
                                            </div>
                                            :
                                            <div onClick={() => this.onPresentType(key, list.title)}>
                                                <img alt="radioOff" className='radio' src={radioOff} />
                                                <label className="radioTxt">{list.title}</label>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    {/* <div className='scroll-auto mb-10px max-height-50vh'> */}
                    <div className='scroll-x'>
                        {/* <div className="row align-item-center justify-content-start" > */}
                        <div className="table-responsive gemsTable" >
                            {appliacantList.length !== 0 ?
                                <table className="table">
                                    <thead className='thred-Fixed-suggestion'>
                                        <tr>
                                            <th scope="col"><div className='thead pr-0'>{"Sr. No"}</div></th>
                                            <th scope="col"><div className='thead pr-0'>{"Applicant Id"}</div></th>
                                            <th scope="col"><div className='thead pr-0'>{"Applicant Name"}</div></th>
                                            <th scope="col"><div className='thead pr-0'>{"Applicant Level"}</div></th>
                                            <th scope="col"><div className='thead pr-0'>{"Applicant Location"}</div></th>
                                            <th scope="col"><div className='thead pr-0'>{"Applicant OU"}</div></th>
                                            {this.state.tableViewKey === "Pending" || this.state.tableViewKey !== "Shortlisted by PG" || this.state.tableViewKey === "Rejected" ?
                                                <th scope="col"><div className='thead pr-10px'>{""}</div></th>
                                                :
                                                <th scope="col"><div className='thead pr-0'>{"PG Status"}</div></th>
                                            }
                                            <th scope="col"><div className='thead pr-0'>{"Action"}</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {appliacantList.map((listValue, index) => {
                                            return (
                                                <tr key={index} >
                                                    <td> <div className="td w-20px">{index + 1}</div></td>
                                                    <td> <div className="td d-flex">{listValue.applicant?.Perno !== null ? listValue.applicant?.Perno : "-"}</div></td>
                                                    <td> <div className="td d-flex">{listValue.applicant?.CompName !== null ? listValue.applicant?.CompName : "-"}</div></td>
                                                    <td> <div className="td d-flex">{listValue.applicant?.Esgtxt !== null ? listValue.applicant?.Esgtxt : "-"}</div></td>
                                                    <td> <div className="td d-flex">{listValue.applicant?.LocDesc !== null ? listValue.applicant?.LocDesc : "-"}</div></td>
                                                    <td> <div className="td d-flex">{listValue.applicant?.Orgtx !== null ? listValue.applicant?.Orgtx : "-"}</div></td>
                                                    {listValue.status === "Shortlisted by PG" || listValue.status === "Rejected by PG" ?
                                                        <td> <div className="td d-flex">{listValue.status !== null ? listValue.status : "-"}</div></td>
                                                        :
                                                        <>
                                                            {listValue.status === "Pending" || listValue.status === "Rejected by SPOC" ?
                                                                <td> <div className="td d-flex">{""}</div></td>
                                                                :
                                                                <td> <div className="td d-flex">{"-"}</div></td>
                                                            }
                                                        </>
                                                    }
                                                    <td>
                                                        {listValue.status === "Pending" ?
                                                            <>
                                                                {listValue.is_approval_active ?
                                                                    <div onClick={() => this.onActionView(listValue, index, "Approval")}>
                                                                        <img alt="view" src={view_action} className='pointer mx-2' />
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <OverlayTrigger placement="left" overlay={this.renderSFTooltipTable(this.props)}>
                                                                            <img
                                                                                alt="infoicon"
                                                                                src={infoicon}
                                                                                className="admin-gems-info-icon"
                                                                            ></img>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <div onClick={() => this.onActionView(listValue, index, "Applicant Details")}>
                                                                <img alt="view" src={view_action} className='pointer mx-2' />
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {this.state.openShortPopup &&
                                            <>
                                                {this.state.appliacantListData !== null ?
                                                    <div className="modal popup-box">
                                                        <div className="popupCard-box p-0 w-33">
                                                            <div className="modal-header">
                                                                <h4 className="modal_title black">{this.state.openRejectPopup ? "Confirm Reason for Rejection" : popupHeader}</h4>
                                                                <button type="button" className="close" data-dismiss="modal" onClick={this.onDismiss}>&times;</button>
                                                            </div>
                                                            {this.state.openRejectPopup ?
                                                                <div className="modal-body h-37vh scroll-y">
                                                                    <div className='justify-content-between p-3 h-70'>
                                                                        <span className='dropDown-title'>{"Reason for Rejection :"} </span>
                                                                        <div className='ml-2 dropdown-text-gems'>
                                                                            <Dropdown
                                                                                data={this.state.newReasonList}
                                                                                selectedValue={(data) => this.changeResonsType(data)}
                                                                                value={this.state.reasonValue}
                                                                                render={'value'}
                                                                                arrow={'true'}
                                                                                height={'NotificationHeight'}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='justify-content-evenly p-3'>
                                                                    <span className='modal-note mr-10'>{"Note:"}</span>
                                                                    <span className='modal-note row'>{Note}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="modal-body h-37vh scroll-y">
                                                                    <div className='m-15px' >
                                                                        <div className='justify-content-start f-gap-1rem line-horizontal'>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"Application Id"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.id ?? "-"}</span>
                                                                            </span>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"Applicant Id"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.applicant?.Perno ?? "-"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className='justify-content-start f-gap-1rem line-horizontal'>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"Applicant Name"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.applicant?.CompName ?? "-"}</span>
                                                                            </span>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"Applicant Level"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.applicant?.Esgtxt ?? "-"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className='justify-content-start f-gap-1rem line-horizontal'>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"Applicant Location"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.applicant?.LocDesc ?? "-"}</span>
                                                                            </span>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"Applicant OU"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.applicant?.Orgtx ?? "-"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className='justify-content-start f-gap-1rem line-horizontal'>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"Application Status"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.status ?? "-"}</span>
                                                                            </span>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"Reject Reason"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.rejection_reason ?? "-"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className='justify-content-start f-gap-1rem line-horizontal'>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"MTR Status"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.mtr_status ?? "-"}</span>
                                                                            </span>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"MTR Comments"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.mtr_comments ?? "-"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className='justify-content-start f-gap-1rem line-horizontal'>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"FR Status"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.fr_status ?? "-"}</span>
                                                                            </span>
                                                                            <span className='pb-10px w-50'>
                                                                                <span className='performance-timeline-text-date'>{"FR Comments"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.fr_comments ?? "-"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className='justify-content-start line-horizontal'>
                                                                            <span className='pb-10px w-100'>
                                                                                <span className='performance-timeline-text-date'>{"Applicant CV"}:&nbsp;&nbsp;</span>
                                                                                <span className='feedback-sm-text'>{appliacantListData.attachment ?? "-"}</span>&nbsp;&nbsp;
                                                                                <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer' onClick={() => this.downloadCV(appliacantListData.id)} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className='modal-footer btnBox1'>
                                                                {popupHeader === "Approval" ?
                                                                    <>
                                                                        {this.state.openRejectPopup ?
                                                                            <input type='button' onClick={() => this.setState({ openRejectPopup: false, isRejectDisable: false, reasonValue: 'Select'})} value='CANCEL' className='cancelBtn' />
                                                                            :
                                                                            <input type='button' onClick={() => this.setState({ openRejectPopup: true, applicantListID: appliacantListData.id, rejectKey: "reject" })} value='REJECT' className='cancelBtn' />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <input type='button' onClick={() => this.onDismiss()} value='CLOSE' className='cancelBtn' />
                                                                }
                                                                {popupHeader === "Approval" && !this.state.openRejectPopup &&
                                                                    <input type='button' value='FORWARD TO PG' className='ml-2 validateBtn' onClick={() => this.approvalBySPOC(appliacantListData.id, "approval")} />
                                                                }
                                                                {this.state.openRejectPopup &&
                                                                    <>
                                                                        {this.state.isRejectDisable ?
                                                                            <input type='button' value='REJECT' className='ml-2 validateBtn' onClick={() => this.approvalBySPOC(appliacantListData.id, "reject")} />
                                                                            :
                                                                            <input type='button' value='REJECT' className='ml-2 validateBtnDisabled' />
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div >
                                                    : null
                                                }</>
                                        }
                                    </tbody>
                                </table>
                                :
                                <span className='justify-content-center align-item-center-noflex flex_1 h-100'>
                                    <NewNotdata />
                                </span>
                            }
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        serviceData: state.activePopupState,
        adminLoginData: state.adminLoginState
    }
}

export default connect(mapStatesToProps)(ApplicantsScreen);