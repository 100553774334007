import * as XLSX from 'xlsx'


export const requestTableCol = [
    { width: "10%", title: "Employee ID", key: "employee", subKey: "Perno", type: "text" },
    { width: "10%", title: "Employee Name", key: "employee", subKey: "CompName", type: "text" },
    { width: "10%", title: "Company", key: "employee", subKey: "Ccodetxt", type: "text" },
    // { width: "10%", title: "Tata Group Joining Date", key: "employee", subKey: "tata_group_joining_date", type: "date" },
    // { width: "10%", title: "TML Joining Date", key: "employee", subKey: "tml_group_joining_date", type: "date" },
    // { width: "10%", title: "Location", key: "employee", subKey: "LocDesc", type: "text" },
    // { width: "10%", title: "OU1", key: "employee", subKey: "Postxt", type: "text" },
    // { width: "5%", title: "Employee Group", key: "employee", subKey: "Egroup", type: "text" },
    // { width: "5%", title: "Employee Sub Group", key: "employee", subKey: "Esubgroup", type: "text" },
    { width: "5%", title: "Pers. Area", key: "employee", subKey: "PersArea", type: "text" },
    { width: "5%", title: "Pers. SubArea", key: "employee", subKey: "PSubarea", type: "text" },
    { width: "10%", title: "Level", key: "employee", subKey: "Esgtxt", type: "text" },
    // { width: "10%", title: "Actual Resignation Date", key: "actual_resignation_date", type: "date" },
    { width: "10%", title: "Last Working Date", key: "separation_date", type: "date" },
    { width: "10%", title: "Separation Reason", key: "esep_action_text", type: "text" },
    { width: "10%", title: "Clearance Status", key: "release_status_text", type: "text" },
]
export const traineeRequestsCol = [
    { width: "10%", title: "Employee Id", key: "employee", subKey: "Perno", type: "text" },
    { width: "10%", title: "Employee Name", key: "employee", subKey: "CompName", type: "text" },
    { width: "20%", title: "Location", key: "employee", subKey: "LocDesc", type: "text" },
    { width: "10%", title: "OU1", key: "employee", subKey: "Postxt", type: "text" },
    { width: "10%", title: "Grade", key: "employee", subKey: "Esgtxt", type: "text" },
    // { width: "10%", title: "Clearance  Status", key: "release_status_text", type: "text" },
    { width: "10%", title: "Last Working Date", key: "separation_date", type: "date" }
]

export const employeeRequestTableCol = [
    { width: "10%", title: "Employee Id", key: "emp_id", type: "text" },
    { width: "10%", title: "Employee Name", key: "employee_name", type: "text" },
    { width: "20%", title: "Location", key: "location", type: "text" },
    { width: "10%", title: "OU1", key: "department", type: "text" },
    { width: "10%", title: "Grade", key: "grd", type: "text" },
    { width: "10%", title: "Clearance  Status", key: "clearance_status", type: "text" },
    { width: "10%", title: "Last Working Date", key: "lwd", type: "date" }
]

export const initiateTableCol = [
    { width: "10%", title: "Employee Id", key: "employee_id", type: "text" },
    { width: "10%", title: "Employee Name", key: "employee_name", type: "text" },
    { width: "10%", title: "Company Name", key: "comp_name", type: "text" },
    { width: "10%", title: "Last Working Date", key: "lwd_recommended_by_bhr", type: "date" },
    { width: "10%", title: "OU1", key: "org_text", type: "text" },
    { width: "10%", title: "Grade", key: "employee_level", type: "text" },
    { width: "20%", title: "Separation Reason", key: "sep_reason", type: "text" },
]

export const reportTable1Col = [
    { width: "5%", title: "Employee ID", key: "emp_id", type: "text" },
    { width: "10%", title: "Employee Name", key: "emp_name", type: "text" },
    { width: "15%", title: "Company", key: "emp_comp_name", type: "text" },
    { width: "10%", title: "Organization Unit 1", key: "emp_department", type: "text" },
    { width: "5%", title: "Employee Group", key: "egroup", type: "text" },
    { width: "5%", title: "Level", key: "esubgroup", type: "text" },
    { width: "5%", title: "Person Area", key: "persarea", type: "text" },
    { width: "5%", title: "Person SubArea", key: "psubarea", type: "text" },
    // { width: "5%", title: "Location", key: "emp_location", type: "text" },
    { width: "10%", title: "Actual Resignation Date", key: "resignation_date", type: "date" },
    { width: "10%", title: "Last Working Date", key: "separation_date", type: "date" },
    // { width: "10%", title: "Separation Reason", key: "action_text", type: "text" },
    // { width: "10%", title: "Reason For Action", key: "reason_text", type: "text" },
    { width: "10%", title: "Clearance Initiation Date", key: "initiated_on", type: "date" },
    { width: "10%", title: "Clearance Agency", key: "clearance_agency", type: "text" },
    { width: "5%", title: "Clearance Status", key: "clearance_status", type: "text" },
    { width: "10%", title: "Clearance Completion Date", key: "released_on", type: "date" },
    // { width: "10%", title: "Clearance Remarks", key: "remark", type: "text" },
    // { width: "10%", title: "Additional Comment 1", key: "additional_comments", subKey: "additional_comment_1", type: "text" },
    // { width: "10%", title: "Additional Comment 2", key: "additional_comments", subKey: "additional_comment_2", type: "text" },
    // { width: "10%", title: "Additional Comment 3", key: "additional_comments", subKey: "additional_comment_3", type: "text" },
    { width: "5%", title: "Revoked Date", key: "revoked_date", type: "date" },
]

export const reportTable2Col = [
    { width: "10%", title: "Pers No", key: "emp_id", type: "text" },
    { width: "15%", title: "Name", key: "emp_name", type: "text" },
    { width: "20%", title: "Location", key: "emp_location", type: "text" },
    { width: "15%", title: "Organization Unit 1", key: "emp_department", type: "text" },
    { width: "15%", title: "Sep Date", key: "separation_date", type: "date" },
    { width: "20%", title: "Clearance Start Date", key: "initiated_on", type: "date" },
]

export const FAndFSettlementReportCol = [
    { width: "5%", title: "Employee ID", key: "emp_id", type: "text" },
    { width: "10%", title: "Employee Name", key: "emp_name", type: "text" },
    { width: "10%", title: "Grade", key: "emp_grade", type: "text" },
    { width: "10%", title: "Location", key: "emp_location", type: "text" },
    { width: "10%", title: "Separation Date", key: "separation_date", type: "date" },
    { width: "10%", title: "Separation Reason", key: "sep_reason", type: "text" },
    { width: "10%", title: "Final Clearance Completed by Agency", key: "final_clearance_completed_by_agency", type: "text" },
    { width: "10%", title: "Last Clearance Date", key: "last_clearance_date", type: "date" },
    { width: "10%", title: "F&F Agency Clearance Date", key: "ff_agency_clearance_date", type: "date" },
    // { width: "10%", title: "F&F Remarks", key: "additional_comments", type: "text" },
]

export const employeeDetailsFields = [
    { label: "Employee ID", key: "Perno" },
    { label: "Employee Name", key: "CompName" },
    { label: "Company", key: "Ccodetxt" },
    { label: "Level", key: "Esgtxt" },
    { label: "Employee Group", key: "Ptext" },
    { label: "Location", key: "LocDesc" },
    { label: "Organization Unit 1", key: "OuLevel1LongTxt" },
    { label: "Person Area", key: "PersArea" },
    { label: "Person SubArea", key: "PSubarea" },
    // { label: "Last Working Date", key: "separation_date" },
]

export const constantVals = {
    timeline: "Clearance Details",
    locallyManagedAgency: "Locally Managed Agencies",
    centrallyManagedAgency: "Centrally Managed Agencies",
    Remark: "Remarks",
    ExtraRemark: "Extra Remarks",
    ClearanceInitiate: "ClearanceInitiate",
    ClearanceRequests: "ClearanceRequests",
    ClearanceReports: "ClearanceReports",
    ClearanceTrainee: "ClearanceTrainee",
    EmployeeDetails: "Employee Details",
    AgencyContactDetails: "Agency Contact Details",
    PayAmount: "Payable Amount",
    RecAmount: "Recovery Amount",
    Remark: "Remarks",
    Comments: "Additional Remarks",
    UploadAttachment: "Upload Attachment",
    AddNewComment: "Add New Additional Remarks",
    NoDuesMessage: "Enter '0' in case of No Dues",
    AssetsDetails: "Employee Assets Details"

}
export const clearancePopupDetails = [
    { label: "Employee ID", key: "Perno" },
    { label: "Employee Name", key: "CompName" },
    // { label: "Employee Level", key: "employee_level" },
    // { label: "Clearance Start Date", key: "clearance_start_date" },
    // { label: "Updated on", key: "updated_on" },
    // { label: "Updated by", key: "updated_by" },
    // { label: "Employee OU1", key: "employee_ou1" },
    // { label: "Employee Location", key: "employee_location" },
    // { label: "Last Working Date", key: "last_date" },
    // { label: "Agency Name", key: "agency_name" },
]

export const agencyWiseReportFullAccessAgencies = ["H2R SPOC", 'F & F Settlement', 'Central Time Office', 'Notice Pay Recovery']
// Master maintenance tables 

export const SPOCMasterFields = [
    { width: "10%", title: "Domain id", key: "domain_id", type: "text" },
    { width: "10%", title: "Agent name", key: "name", type: "text" },
    { width: "10%", title: "Email", key: "email", type: "text" },
    { width: "10%", title: "Contact", key: "mobile_no", type: "text" },
    { width: "10%", title: "Created by", key: "created_by", type: "text" },
    { width: "10%", title: "Created on", key: "created_datetime", type: "dateTime" },
    { width: "10%", title: "Modified by", key: "modified_by", type: "text" },
    { width: "10%", title: "Modified on", key: "modified_datetime", type: "dateTime" }
]
export const SPOCConfigFields = [
    { width: "10%", title: "Domain id", key: "domain_id", type: "text" },
    { width: "10%", title: "Agent name", key: "agent_name", type: "text" },
    { width: "10%", title: "Agency", key: "agency_name", type: "text" },
    { width: "10%", title: "Company", key: "company_name", type: "text" },
    { width: "10%", title: "Pers. Area", key: "pers_area", type: "text" },
    { width: "10%", title: "Pers. SubArea", key: "pers_sub_area", type: "text" },
    { width: "10%", title: "Grade Group Name", key: "grade_grp", type: "text" },
    { width: "10%", title: "Created by", key: "created_by", type: "text" },
    { width: "10%", title: "Created on", key: "created_datetime", type: "dateTime" },
]
export const AgencyGroupFields = [
    { width: "10%", title: "Group name", key: "grp_name", type: "text" },
    { width: "10%", title: "Agency", key: "agency_name", type: "text" },
    { width: "10%", title: "Company", key: "company_name", type: "text" },
    { width: "10%", title: "Pers. Area", key: "pa", type: "text" },
    { width: "10%", title: "Created by", key: "created_by", type: "text" },
    { width: "10%", title: "Created on", key: "created_datetime", type: "dateTime" },
]
export const GradeGroupFields = [
    { width: "10%", title: "Group name", key: "grp_name", type: "text" },
    { width: "10%", title: "Egroup", key: "egroup", type: "text" },
    { width: "10%", title: "Esubgroup", key: "esubgroup", type: "text" },
    { width: "10%", title: "Company", key: "company_name", type: "text" },
    { width: "10%", title: "Pers. Area", key: "pa", type: "text" },
    { width: "10%", title: "Description", key: "grp_desc", type: "text" },
    { width: "10%", title: "Created by", key: "created_by", type: "text" },
    { width: "10%", title: "Created on", key: "created_datetime", type: "dateTime" },
]
export const AgencyMasterFields = [
    { width: "10%", title: "Agency Name", key: "name", type: "text" },
    { width: "10%", title: "Description", key: "description", type: "text" },
    { width: "10%", title: "Type", key: "managed_at", type: "text" },
    { width: "10%", title: "Release Order", key: "release_order", type: "text" },
    { width: "10%", title: "Is Document Applicable", key: "is_document_applicable", type: "boolean" },
    { width: "10%", title: "Created by", key: "created_by", type: "text" },
    { width: "10%", title: "Created on", key: "created_datetime", type: "dateTime" },
    { width: "10%", title: "Modified by", key: "modified_by", type: "text" },
    { width: "10%", title: "Modified on", key: "modified_datetime", type: "dateTime" }
]
export const AgencyConfigFields = [
    { width: "10%", title: "Agency", key: "agency_name", type: "text" },
    { width: "10%", title: "Company", key: "company_name", type: "text" },
    { width: "10%", title: "Pers. Area", key: "pa", type: "text" },
    { width: "10%", title: "Pers. SubArea", key: "psa", type: "text" },
    { width: "10%", title: "Grade Group", key: "grade_grp", type: "text" },
    { width: "10%", title: "Created by", key: "created_by", type: "text" },
    { width: "10%", title: "Created on", key: "created_datetime", type: "dateTime" },
]
export const agentConfigCols = [
    { width: "10%", title: "Agent Name", key: "agent_name", type: "text" },
    { width: "10%", title: "Agency Name", key: "agency_name", type: "text" },
    { width: "5%", title: "Company Name", key: "company_name", type: "text" },
    { width: "5%", title: "PA", key: "pa", type: "text" },
    { width: "5%", title: "PSA", key: "psa", type: "text" },
    { width: "20%", title: "Grade Group Name", key: "grade_grp", type: "text" },
]
export const agencyConfigCols = [
    { width: "10%", title: "Agency Name", key: "agency_name", type: "text" },
    { width: "5%", title: "Company Name", key: "company_name", type: "text" },
    { width: "5%", title: "PA", key: "pa", type: "text" },
    { width: "5%", title: "PSA", key: "psa", type: "text" },
    { width: "20%", title: "Grade Group Name", key: "grade_grp", type: "text" },
]
export const masterLabels = {
    "0": { "addBtn": "+ ADD NEW AGENT", "fields": SPOCMasterFields, "deleteMsg": `All configurations for this agent will be deleted.\nAre you sure that you want to delete the selected agent?` },
    "1": { "addBtn": "+ ADD NEW SPOC CONFIG", "fields": SPOCConfigFields, "deleteMsg": "Are you sure, you want to delete the selected SPOC config?" },
    // "2": { "addBtn": "+ ADD NEW AGENCY", "fields": AgencyMasterFields, "deleteMsg": "Are you sure, you want to delete the selected agency?" },
    // "3": { "addBtn": "+ ADD NEW AGENCY CONFIG", "fields": AgencyConfigFields, "deleteMsg": "Are you sure, you want to delete the selected agency config?" },
    // "4": { "addBtn": "+ ADD GRADE GROUP MAPPING", "fields": GradeGroupFields, "deleteMsg": "Are you sure, you want to delete the selected grade group mapping?" }
}
export const masterTabs = [
    { id: 0, value: "0", title: "SPOC Master" },
    { id: 1, value: "1", title: "SPOC Configuration" },
    // { id: 2, value: "2", title: "Agency Master" },
    // { id: 3, value: "3", title: "Agency Configuration" },
    // { id: 4, value: "4", title: "Grade group mapping" }
]
export const dontHighlightFNF = ["Clearance Request", "Master Maintenance"]
//Employee details popup 

export const agencyDetailsField = [
    { label: "Agency Name", key: "agency_name" },
    // { label: "Item Name", key: "item_name" },
    { label: "Clearance Status", key: "releasing_status" },
    { label: "Agency Clearance Completion Date", key: "releasing_date" },
    { label: "Recovery Amount", key: "recovery_amount" },
    { label: "Payable Amount", key: "payment_amount" },
    // { label: "Remarks", key: "remarks" },
]
export const agencyDetailsPrimaryCol = [
    { width: "7%", title: "Agency Name", key: "agency_name", type: "text" },
    // { width: "10%", title: "Item Name", key: "item_name" },
    { width: "8%", title: "Agency Clearance Completion Date", key: "release_date", type: "date" },
    { width: "8%", title: "Indicative Recovery Amount", key: "recovery_amount", type: "text" },
    { width: "8%", title: "Indicative Payable Amount", key: "pay_amount", type: "text" },
    { width: "10%", title: "Clearance Status", key: "release_status_text", type: "text" },
    { width: "10%", title: "SPOC Details", key: "contact_details", type: "text" },
    // { label: "Remarks", key: "remarks" },
]

export const agencyDetailsFieldInitiate = [
    { label: "Agency Name", key: "agency_name" },
    { label: "Company Code", key: "co_code" },
    { label: "Pers. Area", key: "PersArea" },
    { label: "Pers. SubArea", key: "PSubarea" },
]

export const agencyDetailsInitiatePrimaryCol = [
    { width: "10%", title: "Agency Name", key: "agency_name" },
    { width: "10%", title: "Company Code", key: "co_code" },
    { width: "10%", title: "Pers. Area", key: "PersArea" },
    { width: "10%", title: "Pers. SubArea", key: "PSubarea" },
]
export const contactField = [
    // { label: "Contact Name", key: "name" },
    { label: "SPOC Mobile", key: "mobile_no" },
    { label: "SPOC Email", key: "email" },
]
export const commentField = [
    { label: "Additional Remark", key: "remark", type: "text" },
    { label: "Created by", key: "created_by", type: "text" },
    { label: "Created on", key: "created_datetime", type: "dateTime" },
]
export const uploadNote = `Upload attachment up to 2 MB size only (pdf/xlsx/xls/csv/docx/doc/jpg/jpeg/png/eml/msg) and maximum 5 files are allowed.`

export const fullAccessAgencies = ["H2R SPOC", "F & F Settlement"]

export const assetTableCols = [
    { width: "10%", title: "Employee Asset Name", key: "asset_name" },
    { width: "10%", title: "Employee Asset Serial No.", key: "serial_number" },
    { width: "10%", title: "Employee Asset Location", key: "asset_physical_location" },
    { width: "10%", title: "Employee Asset Type", key: "asset_type" },
    { width: "10%", title: "Employee Asset Assigned To", key: "assigned_to" },
    { width: "10%", title: "Employee Asset Status ", key: "assigned_status" },
]


export const exportTOExcelAOA = (fileName, ExcelExportData, fileExtension, merges = []) => {
    console.log("merges", merges)
    var ws = XLSX.utils.aoa_to_sheet(ExcelExportData);
    if (merges.length > 0) {
        ws['!merges'] = merges
    }
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bulk Registration");
    XLSX.writeFile(wb, fileName + fileExtension);
}
export const exportMaster =["DOWNLOAD MASTER"]

export const docType = ['vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/csv', 'docx', 'xlsx', 'eml', 'msg', 'csv', 'xls', 'application/vnd.ms-excel', 'doc', 'application/msword']
export const imageType = ['png', 'jpg', 'jpeg']

export const selectedOptionStyle = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        color: state.isSelected ? 'white' : provided.color,
        padding: '10px',
        margin: '5px 0',
        cursor: 'pointer',
        ':active': {
            backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        }
    })
}

export const confirmPopupMessage = {
    "0": { "0": "Are you sure that you want to add new agent?", "1": "Are you sure that you want to update the agent details?" },
    "1": { "0": "Are you sure that you want to add new SPOC config?", "1": "Are you sure that you want to update the SPOC Config?" },
    "2": { "0": "Are you sure that you want to add new agency?", "1": "Are you sure that you want to update the agency?" },
    "3": { "0": "Are you sure that you want to add new agency configuration?", "1": "Are you sure that you want to update the agency configuration?" },
    "4": { "0": "Are you sure that you want to add new agency group mapping?", "1": "Are you sure that you want to update the agency group mapping?" },
    "5": { "0": "Are you sure that you want to add new grade group mapping?", "1": "Are you sure that you want to update the grade group mapping?" }
}

export const AgencyTypeList = [
    { id: 0, title: "Locally Managed Agency", value: "1" },
    { id: 1, title: "Centrally Managed Agency", value: "2" },
]
export const monthList = ["January","February","March","April","May","June","July","August","September","October","November","December"]

export const empTypeList = [
    { id: 0, title: "White Collar" },
    { id: 1, title: "Blue Collar"},
]