import React, { Component } from "react";
import "../../../assets/styles/styles.css";
import { connect } from "react-redux";
import notification_icon from "../../../assets/drishti_images/header/notification.svg";
import default_profile from "../../../assets/drishti_images/profile/default_profile.svg";
import ProfileScreen from "../../ScreenType/employeeScreens/profile/ProfileCard";
import NotificationCard from "../../ScreenType/employeeScreens/notification/NotificationCard";
import "./Header.css";
import * as Config from "../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../constants/Service";
import { configStore } from "../../../redux/ConfigStore";
import { bindActionCreators } from "redux";
import * as ActivePopupAction from "../../ScreenType/employeeScreens/landingScreen/ActivePopupAction";
import menuopen from "../../../assets/drishti_images/header/menuopen.svg";
import moments_icon from "../../../assets/drishti_images/header/moments_icon.svg";
import birthday_Wishe from "../../../assets/drishti_images/header/birthday_hat_icon_gif.gif";
import { BASE_WEB_URL_DEV } from '../../../../src/constants/Config'
import Sidebar from "../sidebar/Sidebar";
import { Link } from 'react-router-dom';
import * as AdminLoginAction from "../../../components/ScreenType/adminScreens/login/LoginAction";
import Search from "./Search";

const preProfile = "data:image/png;base64,";
const document_head = "Document Center";
const leave_head = "My Leaves";
const profile_head = "My Profile";
const performance_head = "Continuous Feedback Management";
const announcement_head = "Announcement details";
const moments_that_matter_head = "Moments That Matter";
const exit_head = "Exit Form";
const coi_head = "COI Form";
const attendance_head = "Timesheet";
const notification_details = "Notifications";
const transfer = "Transfer";
const exit_form = "Exit Form";
const admin_coi_head = "COI Form";
const teams = "Team Details";
const todo_head = `To Do's`;
const learningArchitecture = "Learning Landscape";
const eSeparation = "e-Separation";
const eSeparationDashboard = "e-Separation Dashboard";
const isEmployeeLoggedIn = configStore.getState().loginState.isLoggedIn;
const isAdminLoggedIn = configStore.getState().adminLoginState.isAdminLoggedIn
const route_common_heading = "route-common-heading";
const blueCollarRegistrationTable = "Registration";
const GEMS = "GEMS";
class Newheader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProfile: false,
            showNotification: false,
            count: 0,
            serviceData: [],
            showMenuBar: false,
            popupIsVisible: false,
            is_event: false,
            notification_type: []
        };
    }

    componentDidMount() {
        this.isMTMPresentFunction();
        this.getCheckAttendance(this.props.loginData.userData.Perno);
    }

    isMTMPresentFunction = () => {
        const isAdminLogged = configStore.getState().adminLoginState.isAdminLoggedIn
        // # Commented for temporary 

        // {
        //     !isAdminLogged &&
        //         this.getMomentsPresent();
        // }
    }

    getMomentsPresent() {
        this.setState({ loading: true, });
        const headers = {
            "Content-Type": "application/json",
        };
        const body = {
            emp_id: this.props.loginData.userData.Perno
        };
        fetchApiCall(Config.momentThatMatterPresent, body, headers, true, "")
            .then((res) => {
                if (res.data.status_code === "200") {
                    this.setState({
                        is_event: res.data.data.is_event,
                        notification_type: res.data.data.notification_type,
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false

                    });
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
            });

    }

    getServiceAvailability() {
        const headers = {
            "Content-Type": "application/json",
        };
        let request = {};
        fetchApiCall(Config.getServiceAvailabilty, request, headers, false, "")
            .then((res) => {
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    this.setState({ serviceData: res.data.services_status });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    toggleProfile = () => {
        this.setState({ showProfile: !this.state.showProfile });
    };

    showNotification = () => {
        this.setState({
            showNotification: !this.state.showNotification,
        });
    };

    goToMomentsThatMatter = () => {
        window.location = BASE_WEB_URL_DEV + "momentsThatMatter";
    };

    headerTitle = (path) => {
        switch (path) {
            case "/document":
                return <label className={route_common_heading}>{document_head}</label>;
            case "/leave":
                return <label className={route_common_heading}>{leave_head}</label>;
            case "/profile":
                return <label className={route_common_heading}>{profile_head}</label>;
            case "/todo":
                return <label className={route_common_heading}>{todo_head}</label>;
            case "/performance":
                return (
                    <label className={route_common_heading}>{performance_head}</label>
                );
            case "/announcement":
                return (
                    <label className={route_common_heading}>{announcement_head}</label>
                );
            case "/momentsThatMatter":
                return (
                    <label className={route_common_heading}>
                        {moments_that_matter_head}
                    </label>
                );
            case "/exitform":
                return <label className={route_common_heading}>{exit_head}</label>;
            case "/coi":
                return <label className={route_common_heading}>{coi_head}</label>;
            case "/attendance":
                return (
                    <label className={route_common_heading}>{attendance_head}</label>
                );
            case "/notification":
                return (
                    <label className={route_common_heading}>{notification_details}</label>
                );
            case "/teams":
                return <label className={route_common_heading}>{teams}</label>;
            case "/directReportee":
                return <label className={route_common_heading}>{teams}</label>;
            case "/learninglandscape":
                return (
                    <label className={route_common_heading}>{learningArchitecture}</label>
                );
            case "/esepRequest":
                return (
                    <label className={route_common_heading}>{eSeparation}</label>
                );
            case "/admin/notification/home":
                return (
                    <label className={route_common_heading}>{notification_details}</label>
                );
            case "/admin/transfer":
                return <label className={route_common_heading}>{transfer}</label>;
            case "/admin/leave":
                return <label className={route_common_heading}>{leave_head}</label>;
            case "/admin/todo":
                return <label className={route_common_heading}>{todo_head}</label>;
            case "/admin/exit":
                return <label className={route_common_heading}>{exit_form}</label>;
            case "/admin/eseparationHome":
                return <label className={route_common_heading}>{eSeparationDashboard}</label>;
            case "/admin/coi":
                return <label className={route_common_heading}>{admin_coi_head}</label>;
            // case "/admin/registration":
            //     return <label className={route_common_heading}>{blueCollarRegistration}</label>
            case "/admin/registrationinfo":
                return <label className={route_common_heading}>{blueCollarRegistrationTable}</label>
            case "/admin/gems":
                return <label className={route_common_heading}>{GEMS}</label>;
            default:
                return (
                    <div>
                        <label className="welcome-text">Hello,</label>
                        <label className="emp-name">
                            {this.props.adminLoginData.isAdminLoggedIn
                                    ? (this.props.adminLoginData.userData.Firstname && this.props.adminLoginData.userData.Firstname.trim() !== "" 
                                        ? this.props.adminLoginData.userData.Firstname : "Admin")
                                            : this.props.loginData.userData.Firstname }
                            !
                        </label>
                    </div>
                );
        }
    };

    getCheckAttendance(employeeId) {
        if (isEmployeeLoggedIn) {
            this.setState({
                loading: true,
            });
            const headers = {
                "Content-Type": "application/json",
            };
            const body = {
                empId: employeeId,
            };
            console.warn("getCheckAttendance body:", body);
            fetchApiCall(Config.checkAttendance, body, headers, false, "")
                .then((res) => {
                    console.warn("getCheckAttendance res:", res);
                    sessionOut(this.props, res);
                    if (res.data.statusCode === "200") {
                        this.setState({
                            count: res.data.unread_notification_count
                                ? res.data.unread_notification_count
                                : [],
                            loading: false,
                        });
                    } else {
                        this.setState({
                            loading: false,
                            count: 0,
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                    });
                });
        }
    }

    toggleMenu = () => {
        this.setState({
            showMenuBar: !this.state.showMenuBar,
        });
    };

    render() {
        const profileImg = this.props.loginData.profileImage;
        const { showProfile, showNotification, showMenuBar } = this.state;
        const companyName = configStore.getState().loginState.company.company_sht_txt;
        return (
            <div>
                <div className="outer-header justify-content-center">
                    <div className="d-flex justify-content-between align-item-center">
                        <div>
                            <div className="web600">
                                <div>
                                    <label
                                        className={
                                            window.location.pathname === "/home"
                                                ? "header-text"
                                                : "header-text-t"
                                        }
                                    >
                                        DRISHTI 2.0
                                    </label>
                                </div>
                                <div>{this.headerTitle(window.location.pathname)}</div>
                            </div>
                            <div className="mobile">
                                <img
                                    alt="Menu"
                                    src={menuopen}
                                    className="menu-icon"
                                    onClick={this.toggleMenu}
                                />
                            </div>
                        </div>
                        <div>
                        </div>
                        <div className="d-flex justify-content-between align-item-center-noflex">
                            <Search />
                            {!this.props.adminLoginData.isAdminLoggedIn &&
                                <>
                                    {this.state.is_event ?
                                        <Link to={'/momentsThatMatter'}>
                                            <img
                                                src={birthday_Wishe}
                                                alt="Birthday"
                                                className="birthday-right-icon "
                                            ></img></Link>
                                        :
                                        <Link to={'/momentsThatMatter'}>
                                            <img
                                                src={moments_icon}
                                                alt="Movemnts"
                                                className="moments-right-icon "

                                            ></img></Link>
                                    }
                                </>
                            }
                            {this.state.count !== 0 && (
                                <div className="badge" onClick={this.showNotification}>
                                    {this.state.count}
                                </div>
                            )}
                            {!this.props.adminLoginData.isAdminLoggedIn &&
                                <>
                                    <img
                                        alt="Notification"
                                        src={notification_icon}
                                        className="notification-icon"
                                        onClick={this.showNotification}
                                    />
                                </>
                            }
                            {this.props.adminLoginData.isAdminLoggedIn ?
                                <>
                                    {this.props.adminLoginData.profileImage != "" ?
                                        <img
                                            alt="avatar"
                                            src={preProfile + this.props.adminLoginData.profileImage}
                                            className="header-right-icon"
                                            onClick={this.toggleProfile}
                                            data-toggle="modal"
                                        ></img>
                                        :
                                        <img
                                            alt="avatar"
                                            src={default_profile}
                                            className="profile-sm-default"
                                            onClick={this.toggleProfile}
                                            data-toggle="modal"
                                        ></img>
                                    }
                                </>
                                :
                                <>
                                    {profileImg != "" ?
                                        <img
                                            alt="avatar"
                                            src={preProfile + this.props.loginData.profileImage}
                                            className="header-right-icon"
                                            onClick={this.toggleProfile}
                                            data-toggle="modal"
                                        ></img>
                                        :
                                        <img
                                            alt="avatar"
                                            src={default_profile}
                                            className="profile-sm-default"
                                            onClick={this.toggleProfile}
                                            data-toggle="modal"
                                        ></img>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>

                {showProfile && (
                    <div className="flex-direction-column">
                        <div className="tipdiv"> </div>
                        <ProfileScreen
                            onDismiss={this.toggleProfile}
                            loginData={this.props.loginData}
                        />
                    </div>
                )}
                {showNotification && (
                    <div className="flex-direction-column">
                        <div className="tipnotification"></div>
                        <NotificationCard
                            onDismiss={this.showNotification}
                            loginData={this.props.loginData}
                            count={this.state.count}
                        />
                        {/* <Badge badgeContent={this.state.count}
                            color="primary">
                        </Badge> */}
                    </div>
                )}
                {this.state.showMenuBar && (
                    <Sidebar showMenu={showMenuBar} onDismiss={this.toggleMenu} />
                )}
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
        adminLoginData: state.adminLoginState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        serviceAction: bindActionCreators(ActivePopupAction, dispatch),
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
    };
};

export default connect(mapStatesToProps, mapDispatchToProps)(Newheader);
