import React, { useEffect, useState } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import DataNotFound from '../../../shared/notFound/DataNotFound';

const validationNotes = `To unlock the IT Sheet, please enter a valid PAN card number (in capital letters) or a Date Of Birth in the correct format (DDMMYYYY).`
function DocumentPreviewScreen(props) {
    const {MockITSheetUrl,onClose } = props
    const [loading, setLoading] = useState(false)

    return (
        <>
            <LoaderIndicator loading={loading} />
            {
                <div className="modal popup-box" >
                    <div className={"popupCard-box p-0 w-50"}>
                        <div className="modal-header">
                            <h4 className="modal_title black">{`Mock Income Tax Worksheet - ${new Date().toLocaleString('default', { month: 'long' })}  ${new Date().getFullYear()}`}</h4>
                            <button type="button" className="close" data-dismiss="modal"
                                onClick={onClose}
                            >
                                &times;</button>
                        </div>
                        <div className='modal-body m-15px scroll-auto'>
                            <div className='d-flex flex-direction-row'>
                                <div className='purple-color performance-timeline-text-circle ml-3 mt-3'>{'Note:'}</div>
                                <div className='welcomomepopupdescription ml-1'>
                                    {validationNotes}
                                </div>
                            </div>
                            {/* {
                                download &&
                                <div className='row gap-10 mb-3 justify-content-end pointer' onClick={(e) => { downloadFile(selectedUploadBill) }}>
                                    <div className='add-btn-text'>Download</div>
                                    <img alt="view" src={downloadLatestImg} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                </div>
                            } */}
                            <span className='flex-direction-column'>
                                <span className='d-flex flex-direction-column px-10px pb-10px'>
                                    {MockITSheetUrl ? (
                                        <iframe
                                            className='upload_bills_modal_body'
                                            src={`${MockITSheetUrl}#toolbar=0&view=fitH`}
                                            // title="PDF Viewer"
                                            // style={{ width: '100%', height: '500px', border: 'none' }}
                                        />
                                    ) : (
                                        <DataNotFound/>
                                    )}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default DocumentPreviewScreen