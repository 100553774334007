import React, { Component } from 'react';
import { LeaveMonth } from '../../../../../utils/Utils';
import Loader from '../../../../shared/loader/LoaderIndicator'
import * as Config from "../../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../../constants/Service";
import NudgePopup from './NudgePopUp';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import { Progress } from 'antd';
import "react-circular-progressbar/dist/styles.css";
import '../../../employeeScreens/leave/LeaveScreen.css'
// import prev from '../../../../../assets/images/applyLeave/backArrow.png'
// import next from '../../../../../assets/images/applyLeave/nextArrow.png'
import infoicon from '../../../../../assets/drishti_images/infoicon.svg';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import exportExcelcon from '../../../../../assets/drishti_images/admin/exportExcelcon.svg';
import 'antd/dist/antd.css';
import CommonButton from '../../../../shared/button/GradientButton';
import DateFilterPopup from '../../../employeeScreens/attendance/DateFilterPopup';
import QuotaDetailPopup from '../../../employeeScreens/leave/QuotaDetailPopup';

class SummaryScreen extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            openPopUp: false,
            isAttendanceReportPopup: false,
            goldenData: [
                {
                    id: 1,
                    src: require('../../../../../assets/drishti_images/manager/teams/golden.svg')
                },
                {
                    id: 2,
                    src: require('../../../../../assets/drishti_images/manager/teams/golden.svg')
                },
                {
                    id: 3,
                    src: require('../../../../../assets/drishti_images/manager/teams/golden.svg')
                },
            ],
            silverData: [
                {
                    id: 1,
                    src: require('../../../../../assets/drishti_images/manager/teams/silver.svg')
                },
                {
                    id: 2,
                    src: require('../../../../../assets/drishti_images/manager/teams/silver.svg')
                }
            ],
            leaveData: LeaveMonth(),
            todoSelect: 'Goal',
            leaveQuotaData: [],
            leaveQuotaData1: [
                {
                    id: 1,
                    name: '1',
                    count: '1'
                },
                {
                    id: 1,
                    name: '2',
                    count: '1'
                },
                {
                    id: 1,
                    name: '3',
                    count: '1'
                },
                {
                    id: 1,
                    name: '4',
                    count: '1'
                }
            ],
            casualTotal: '',
            casualAvailable: '',
            casualText: '',
            privilegeTotal: '',
            privilegeAvailable: '',
            privilegeText: '',
            sickTotal: '',
            sickAvailable: '',
            sickText: '',
            availableOutcome: '',
            totalOutcome: '',
            quotatDetails: [],
            settings: {},
            isPopupOpen: false,
            leaveQuotaDetails: [],
            clickItem: [],
            start: 0,
            end: 3
        }
    }
    componentDidMount() {
        this.getAvailedLeaveData()
    }

    getAvailedLeaveData = () => {
        this.setState({
            loading: true
        });
        const body = { 
            emp_id: this.props.clickid,
            pa: this.props.PersArea,
            psa: this.props.PSubarea
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(
            Config.getAvailedLeavesData, body, headers, false, ''
        ).then(res => {
            sessionOut(this.props, res);
            if (res.data.status_code == 200) {
                this.setState({
                    loading: false,
                    leaveQuotaData: res.data.leave_quota ? res.data.leave_quota : [],

                    total_leaves: res.data.total_outcome,
                    availed_leaves: res.data.available_outcome,

                    casualTotal: res.data.leave_quota[0]?.total_leaves,
                    casualAvailable: res.data.leave_quota[0]?.available_leaves,
                    casualText: res.data.leave_quota[0]?.leave_category_text,

                    privilegeTotal: res.data.leave_quota[1]?.total_leaves,
                    privilegeAvailable: res.data.leave_quota[1]?.available_leaves,
                    privilegeText: res.data.leave_quota[1]?.leave_category_text,

                    sickTotal: res.data.leave_quota[2]?.total_leaves,
                    sickAvailable: res.data.leave_quota[2]?.available_leaves,
                    sickText: res.data.leave_quota[2]?.leave_category_text,

                    availableOutcome: res.data.available_outcome,
                    totalOutcome: res.data.total_outcome,
                },
                    this.setProgressbar(
                        res.data.leave_quota[0]?.total_leaves,
                        res.data.leave_quota[0]?.available_leaves,
                        res.data.leave_quota[1]?.total_leaves,
                        res.data.leave_quota[1]?.available_leaves,
                        res.data.leave_quota[2]?.total_leaves,
                        res.data.leave_quota[2]?.available_leaves,
                        res.data.available_outcome,
                        res.data.total_outcome
                    )
                )
            } else {
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            this.setState({
                loading: false
            });
        })
    }

    setProgressbar = (casualTotal, casualAvailable, privilegeTotal, privilegeAvailable, sickTotal, sickAvailable, availableOutcome, totalOutcome) => {
        document.documentElement.style.setProperty('--casualWidth', `${((casualTotal - casualAvailable) / casualTotal) * 100}%`);
        document.documentElement.style.setProperty('--availedWidth', `${((totalOutcome - availableOutcome) / totalOutcome) * 100}%`);
        document.documentElement.style.setProperty('--sickLeaveWidth', `${((sickTotal - sickAvailable) / sickTotal) * 100}%`);
        document.documentElement.style.setProperty('--privilegeLeaveWidth', `${((privilegeTotal - privilegeAvailable) / privilegeTotal) * 100}%`);
    }

    openPopUp = () => {
        this.setState({
            openPopUp: false
        })
    }

    usedLeave = (total, available) => {
        const usedLeave = total - available
        console.log('used', usedLeave);
        return usedLeave
    }

    renderSFTooltip = props => (
        <div className='tool-tip-teams'>
            <div className='tool-tip-teams'>{props}</div>
        </div>
    );

    handleButtonClick = () => {
        this.setState({ isAttendanceReportPopup: !this.state.isAttendanceReportPopup })
    }

    leaveQuataPopup = (item, isPopupOpen) => {
        console.log(item, isPopupOpen);
        this.setState({
            leaveQuotaDetails: item,
            isPopupOpen: isPopupOpen
        })
    }

    closePopUp = () => { this.setState({ isPopupOpen: false, leaveQuotaDetails: [] }) }

    render() {
        console.log('doj---------->', this.props.reporteeDoj);
        return (
            <div className='scroller-coi'>
                <Loader loading={this.state.loading} />
                {/* <div className='cfm-subcard'>
                    <div>
                        <div className="mx-4">
                            <label className='summary-heading mt-3'>Rewards & Appreciation</label>
                        </div>
                        <div className='d-flex justify-content-around align-item-center mt-4'>
                            <div className='row justify-content-center align-item-center'>
                                <label className='summary-badge-count'>3</label>
                                <h1 className='summary-badge-label mx-2 mt-2'>Badges</h1>
                            </div>
                            <div className='row justify-content-center align-item-center'>
                                <label className='summary-badge-count'>2</label>
                                <h1 className='summary-badge-label mx-2 mt-1'>Trophies</h1>
                            </div>
                        </div>
                        <div className='justify-content-around align-item-center mt-2'>
                            <div className='row col-6 justify-content-center align-item-center mb-3'>
                                {this.state.goldenData.map((listValue, id) => {
                                    return (
                                        <div>
                                            <img className='summary-golden-badge-img mx-3'
                                                src={listValue.src}
                                            ></img>
                                            <h1 className='row summary-badge-label justify-content-center align-item-center'>1</h1>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='row col-6 justify-content-center align-item-center mb-3'>
                                {this.state.silverData.map((listValue, id) => {
                                    return (
                                        <div>
                                            <img className='summary-silver-badge-img mx-3'
                                                src={listValue.src}
                                            ></img>
                                            <h1 className='row summary-badge-label justify-content-center align-item-center'>1</h1>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='cfm-subcard'>
                    <div>
                        <div className='justify-content-between align-item-center'>
                            <div className="mx-4">
                                <label className='summary-heading mt-3'>{'Leave Quota'}</label>
                            </div>

                            <div className='mt-2 flex-direction-row'>
                                <CommonButton
                                    label="NUDGE FOR LEAVE"
                                    onClick={() => this.setState({ openPopUp: true })}
                                    isGradientBtn={true}
                                />
                                <div className="d-flex mr-3 ml-2 mt-1" onClick={this.handleButtonClick}>
                                    <OverlayTrigger placement="top" overlay={this.renderSFTooltip('GENERATE ATTENDANCE REPORT')}>
                                        <img
                                            alt="infoicon"
                                            src={exportExcelcon}
                                            className="filterIcon"
                                        ></img>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-direction-row justify-content-around'>
                            <div className='d-flex flex-direction-row justify-content-center'>
                                {this.state.leaveQuotaData.length !== 0 &&
                                    <div className='mt-4 ml-4'>
                                        <div className='circle-bg-gradient'>
                                            <Progress
                                                type="circle"
                                                strokeColor={{
                                                    '0%': '#4B83D9',
                                                    '50%': '#6881D7',
                                                    '100%': '#8C7ED4',
                                                }}
                                                trailColor={'#F1F5F9'}
                                                strokeWidth={4}
                                                width={101}
                                                status={'normal'}
                                                percent={(((this.state.totalOutcome - this.state.availableOutcome) / this.state.totalOutcome) * 100).toFixed(1)}
                                            />
                                        </div>
                                        <div className='ml-2 justify-content-center flex-column align-content-center mb-1 mt-2'>
                                            <div className='text17_light'>{'Leaves Availed'}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {!this.state.loading && <div className='vertical-line-style'></div>}
                            <div className='scroll-x'>
                                {this.state.leaveQuotaData.length !== 0 ?
                                    <div className='leave-quota-outer-card d-flex justify-content-center align-item-center pointer w-32rem h-8rem'>
                                        {this.state.leaveQuotaData.map((item, index) =>
                                            <div key={index} className='mx-4'
                                                onClick={() => this.leaveQuataPopup(item, true)}
                                            >
                                                <Progress
                                                    type="circle"
                                                    strokeColor={{
                                                        '0%': (item.leave_category_color).split(' ')[1],
                                                        '100%': (item.leave_category_color).split(' ')[3],
                                                    }}
                                                    trailColor={'#F1F5F9'}
                                                    strokeWidth={4}
                                                    width={100}
                                                    status={'normal'}
                                                    percent={(((item.total_leaves - item.available_leaves) / item.total_leaves) * 100)}
                                                    format={() => `${(item.total_leaves - item.available_leaves) + `/` + (item.total_leaves)}`}
                                                />
                                                <div className='text17_light mt-2 ml-2'>{item.leave_category_text}</div>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    <div className='marginLeaveLR'>
                                        <DataNotFound />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.openPopUp &&
                    <NudgePopup
                        Perno={this.props.loginData.userData.Perno}
                        CompName={this.props.loginData.userData.CompName}
                        EmailID={this.props.loginData.userData.Imailid}
                        ReporteeEmpId={this.props.ReporteeEmpId}
                        ReporteeName={this.props.ReporteeName}
                        ReporteeEmailId={this.props.ReporteeEmailId}
                        clickid={this.props.clickid}
                        openPopUp={this.openPopUp}
                        type={"Nudge for leave"}
                    />
                }

                {this.state.isAttendanceReportPopup &&
                    <DateFilterPopup
                        title={'Download Attendance report'}
                        action={'Teams'}
                        reporteeEmpId={this.props.clickid ? this.props.clickid : ''}
                        reporteeDoj={this.props.reporteeDoj ? this.props.reporteeDoj : ''}
                        reporteeName={this.props.reporteeName ? this.props.reporteeName : ''}
                        onDismiss={this.handleButtonClick}
                    />
                }
                {this.state.isPopupOpen &&
                    <QuotaDetailPopup
                        action={'Teams'}
                        data={this.state.leaveQuotaDetails ? this.state.leaveQuotaDetails : []}
                        onDismiss={this.closePopUp}
                    />
                }
            </div>
        );
    }
}

export default SummaryScreen;